import {Button, Tooltip, OverlayTrigger} from "react-bootstrap";
import {PiSpeakerSimpleHighFill, PiSpeakerSimpleSlashFill} from "react-icons/pi";
import {BsFillMicFill, BsFillMicMuteFill} from "react-icons/bs";
import ChatContext from "../context/ChatContext";
import React, {useContext, useEffect, useRef} from "react";
import {useSendMessage} from "../hooks/useSendMessage";
import {t} from "i18next";


const recognitionClass =
    (window as any).SpeechRecognition ||
    (window as any).webkitSpeechRecognition ||
    (window as any).mozSpeechRecognition;
const recognition = new recognitionClass();
recognition.lang = "hu-HU";
recognition.continuous = true;
recognition.interimResults = true;
recognition.maxAlternatives = 1;
let browserSupported = true;
if (!recognition) {
    browserSupported = false;
}

const Audio = () => {

    const timerRef = useRef<NodeJS.Timeout>();
    const sendMessage = useSendMessage();
    const {
        audioState,
        setAudioState,
        setMessage
    } = useContext(ChatContext);

    const handleClickRecognition = () => {
        setAudioState(audioState == 'disabled' ? 'microphone' : 'disabled');
    };

    const handleOnEnded = () => {
        setAudioState('disabled');
    };

    useEffect(() => {
        // Clear the interval when the component unmounts
        return () => {
            clearTimeout(timerRef.current);
            recognition.stop();
        }
    }, []);

    useEffect(() => {
        if (audioState == 'microphone') {
            recognition.start();
        } else {
            recognition.stop();
        }
    }, [audioState]);

    recognition.onresult = (event: any) => {
        let text = Array.from(event.results)
            .map((result: any) => result[0])
            .map((result) => result.transcript)
            .join("");
        setMessage(text);
        if (event.results[event.results.length - 1].isFinal) {
            clearTimeout(timerRef.current);
            timerRef.current = setTimeout(() => {
                setAudioState('disabled');
                setMessage('');
                sendMessage();
            }, 2000);
        }
    };

    return (
        <>
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="button-tooltip-2">
                    <div>{t('chat.speech-to-text')}</div>
                </Tooltip>}
            >
                <Button
                    onClick={handleClickRecognition}
                    disabled={false}
                >
                    {audioState == 'disabled' && <PiSpeakerSimpleSlashFill/>}
                    {audioState == 'microphone' && <BsFillMicFill/>}
                    {audioState == 'sending' && <BsFillMicMuteFill/>}
                    {audioState == 'speaker' && <PiSpeakerSimpleHighFill/>}
                </Button>
            </OverlayTrigger>
        </>
    )
}

export default Audio;