import { createContext, useContext } from 'react';

interface LanguageContextProps {
    language: string;
    setLanguage: (language: string) => void;
    languages: { [key: string]: string };
    defaultLanguage: string;
}

export const LanguageContext = createContext<LanguageContextProps | undefined>(undefined);

export const useLanguage = () => {
    const context = useContext(LanguageContext);
    if (!context) {
        throw new Error('useLanguage must be used within a LanguageProvider');
    }
    return context;
};

export const languages = {
  en: "English",
  hu: "Magyar",
};

export const defaultLanguage = "hu";
