import {useEffect, useState} from "react";
import {Chart} from "react-google-charts";
import {Api} from "../../../services/api";
import {t} from "i18next";

const PlatformStat = () => {
    const [loaging, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<(string | number)[][]>([
        [t("stat.platform"), t("stat.usage")],
    ]);

    const options = {
        backgroundColor: 'transparent',
        chartArea: {left: 20, top: 20, width: '90%', height: '95%'},
        is3D: true,
    }

    const getStat = () => {
        try {
            setLoading(true);
            Api.get<any>('stats/platforms').then((response) => {
                const apiData = response.data;
                const newData = [[t("stat.platform"), t("stat.usage")]];

                const keyReplacements: any = {
                    'react': 'cognitudo web',
                };

                Object.keys(apiData).forEach(key => {
                    const platformName = keyReplacements[key] || key;
                    newData.push([platformName, apiData[key]]);
                });
                setData(newData);
            }).catch((error) => {
                console.error(error)
            })
        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getStat();
    }, []);

    return (
        <div className="card shadow-sm">
            <div className="card-header">
                <h5 className="mb-0">{t("stat.platform_stat_title")}</h5>
                <p className="mb-0">{t("stat.platform_stat_subtitle")}</p>
            </div>
            <div className="card-body">
                <Chart
                    chartType="PieChart"
                    data={data}
                    options={options}
                    width={"100%"}
                    height={"400px"}
                />
            </div>
        </div>
    );
}

export default PlatformStat;