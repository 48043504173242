import {useAccordionButton} from "react-bootstrap";
import React from "react";

interface CustomToggleProps {
    children: React.ReactNode;
    eventKey: string;
}

const AccordionCustomBodyToggle = ({children, eventKey}: CustomToggleProps) => {
    const decoratedOnClick = useAccordionButton(eventKey);
    return (
        <div onClick={decoratedOnClick} style={{'cursor': 'pointer'}}>
            {children}
        </div>
    );
}
export default AccordionCustomBodyToggle;