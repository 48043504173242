import {Container, Tab, Tabs} from "react-bootstrap";
import {t} from "i18next";
import {useEffect, useState} from "react";
import {Toaster} from "react-hot-toast";

import Documents from "components/documents/Documents";
import Assistants from "../assistant/Assistants";
import StatPage from "../stat/StatPage";
import AudioPage from "../audio/AudioPage";
import GroupPage from "../group/GroupPage";
import {usePermissions} from "../../hooks/usePermissions";
import FilePage from "../file/FilePage";

const Home = () => {
    const [activeKey, setActiveKey] = useState<string>(() => {
        const tabKey = sessionStorage.getItem("tabKey");
        if (tabKey) {
            return tabKey;
        }
        return 'audios';
    });

    const {checkPermissions} = usePermissions();

    useEffect(() => {
        checkPermissions(['audio.view_audio', 'video.view_video']);
        const apiUrl = localStorage.getItem('apiUrl');
        if (!apiUrl) {
            localStorage.removeItem('token');
            localStorage.removeItem('user_id');
            window.location.href = '/edit-url';
        }
    }, []);

    const handleTabChange = (key: string) => {
        setActiveKey(key);
        sessionStorage.setItem("tabKey", key);
    }

    return (
        <Container className="position-relative pb-5 chat-container">
            <Tabs
                id="controlled-tabs-list"
                className="mt-4 px-5"
                activeKey={activeKey}
                onSelect={(k) => handleTabChange(k ?? '')}
            >
                <Tab eventKey="assistants" title={
                    <div className="d-inline-flex align-items-center justify-content-center">
                        <img className="me-1" src="images/assistant.png" alt={t('assistants.title')} width={22}/>
                        <span>{t('assistants.title')}</span>
                    </div>
                } className="px-2">
                    {activeKey === 'assistants' && <Assistants/>}
                </Tab>
                {/*<Tab eventKey="documents" title={*/}
                {/*    <div className="d-inline-flex align-items-center justify-content-center">*/}
                {/*        <img className="me-1" src="images/knowledge_base.png" alt={t('assistants.title')} width={28}/>*/}
                {/*        <span>{t('documents.title')}</span>*/}
                {/*    </div>*/}
                {/*} className="px-2">*/}
                {/*    {activeKey === 'documents' && <Documents/>}*/}
                {/*</Tab>*/}
                {/*<Tab eventKey="audios" title={*/}
                {/*    <div className="d-inline-flex align-items-center justify-content-center">*/}
                {/*        <img className="me-1" src="images/audio_video.png" alt={t('assistants.title')} width={28}/>*/}
                {/*        <span>{t('audio.subtitles')}</span>*/}
                {/*    </div>*/}
                {/*} className="px-2">*/}
                {/*    {activeKey === 'audios' && <AudioPage/>}*/}
                {/*</Tab>*/}
                <Tab eventKey="file" title={
                    <div className="d-inline-flex align-items-center justify-content-center">
                        <img className="me-1" src="images/stat.png" alt={t('file.tab_title')} width={28}/>
                        <span>{t('file.tab_title')}</span>
                    </div>
                } className="px-2">
                    {activeKey === 'file' && <FilePage/>}
                </Tab>
                <Tab eventKey="group" title={
                    <div className="d-inline-flex align-items-center justify-content-center">
                        <img className="me-1" src="images/stat.png" alt={t('group.tab_title')} width={28}/>
                        <span>{t('group.tab_title')}</span>
                    </div>
                } className="px-2">
                    {activeKey === 'group' && <GroupPage/>}
                </Tab>
                <Tab eventKey="stat" title={
                    <div className="d-inline-flex align-items-center justify-content-center">
                        <img className="me-1" src="images/stat.png" alt={t('assistants.title')} width={28}/>
                        <span>{t('stat.title')}</span>
                    </div>
                } className="px-2">
                    {activeKey === 'stat' && <StatPage/>}
                </Tab>
            </Tabs>
            <Toaster/>
        </Container>
    );
};

export default Home;