import {TbFileStack} from "react-icons/tb";
import React from "react";
import {IMessage} from "../../../../interfaces/IMessage";
import {Modal} from "react-bootstrap";
import {t, use} from "i18next";

const MessageSourcesBtn = ({message}: { message: IMessage }) => {
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const dataArray = Object.values(message.sources);

    return (
        <div>
            <button type="button"
                    className={`btn btn-link btn-sm d-flex justify-content-center align-items-center ${dataArray.length === 0 ? 'd-none' : ''}`}
                    onClick={e => setShowModal(true)}>
                <TbFileStack size={18}/>
            </button>

            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title as='span'>{t("message_buttons.sources")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {dataArray.map((item: any, index: number) => (
                        <div key={index} className='py-2 px-3 rounded border border-1 mb-2'>
                            <div>{t("message_buttons.type")}: {item.doc_type === 'audio' ? t("message_buttons.doc_type_audio") : t("message_buttons.doc_type_document")}</div>
                            {t("message_buttons.title")}: <a target='_blank' className='downloadUrl' href={item.download_url} download
                               rel="noreferrer">{item.title}</a>
                            {/*<div>*/}
                            {/*    {item.data.map((data: any, index: number) => (*/}
                            {/*        <span key={index}>[{data.source_counter}]</span>*/}
                            {/*    ))}*/}
                            {/*</div>*/}
                        </div>
                    ))}
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default MessageSourcesBtn;