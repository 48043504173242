import MessagePlayAudioBtn from "./buttons/MessagePlayAudioBtn";
import React from "react";
import MessageClipboardBtn from "./buttons/MessageClipboardBtn";
import MessageDislikeBtn from "./buttons/MessageDislikeBtn";
import MessageSourcesBtn from "./buttons/MessageSourcesBtn";
import {IMessage} from "../../../interfaces/IMessage";
import TooltipLayer from "../../../components/tooltip/tooltip";

const MessageButtons = ({message, assistant_id, sources_show}: { message: IMessage, assistant_id: string, sources_show: boolean }) => {
    return <div
        className='d-inline-flex justify-content-start align-items-center gap-2 mt-2 chat-message-icons'>
        <MessagePlayAudioBtn content={message.content} assistant_id={assistant_id}></MessagePlayAudioBtn>
        <MessageClipboardBtn content={message.content}></MessageClipboardBtn>
        {sources_show && <MessageSourcesBtn message={message}></MessageSourcesBtn>}
        <MessageDislikeBtn message={message}></MessageDislikeBtn>
    </div>
}

export default MessageButtons;