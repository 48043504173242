import {t} from "i18next";
import {Form} from "react-bootstrap";
import {FormEvent, SetStateAction, useEffect, useState} from "react";
import toast, {Toaster} from "react-hot-toast";

const EditUrlPage = () => {
    const [apiUrl, setApiUrl] = useState<string>('');

    useEffect(() => {
        const url = localStorage.getItem('apiUrl')
        setApiUrl(url ?? '')
    }, []);

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (!form.checkValidity()) {
            toast.error(t('edit_url.formToastError01'), {position: "bottom-center"});
            return;
        }

        if (apiUrl && !apiUrl.startsWith('http://')) {
            //console.log(t('edit_url.formToastError02'))
            //toast.error(t('edit_url.formToastError02'), {position: "bottom-center"});
            //return;
        }

        const toastId = toast.loading(t('edit_url.saveToastLoading'), {position: "bottom-center"})
        try {
            const _apiUrl = apiUrl.endsWith('/') ? apiUrl : apiUrl + '/';
            await fetch(_apiUrl + "account/url-check", {
                method: "GET",
            }).then(response => {
                if (response.status === 201) {
                    setTimeout(function () {
                        toast.success(t('edit_url.saveToastSuccess'), {
                            id: toastId,
                            position: "bottom-center"
                        });
                        localStorage.setItem('apiUrl', _apiUrl ?? '');
                        localStorage.removeItem('token');
                        localStorage.removeItem('user_id');
                        setTimeout(function () {
                            window.location.href = '/login';
                        }, 1600)
                    }, 2000);
                } else {
                    toast.error(t('edit_url.formToastError02'), {
                        id: toastId,
                        position: "bottom-center"
                    });
                }
            });
        } catch (error) {
            console.log(toastId)
            toast.error(t('edit_url.formToastError02'), {
                id: toastId,
                position: "bottom-center"
            });
        }
    };

    return (
        <>
            <div className="position-relative pb-5 d-flex align-items-center justify-content-center w-100">
                <div className="row w-100">
                    <div className="col-md-12 col-lg-4 mx-auto">
                        <div className="card shadow-sm" style={{maxHeight: "400px"}}>
                            <div className="card-body">
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>{t('edit_url.title')}</Form.Label>
                                        <Form.Control type="text" onChange={(e) => setApiUrl(e.target.value)}
                                                      placeholder={t('edit_url.formInputPlaceholder')}
                                                      value={apiUrl}
                                                      aria-describedby="urlHelpBlock"
                                                      required/>
                                        <Form.Text id="urlHelpBlock" muted>{t('edit_url.formInputHelpTxt')}</Form.Text>
                                    </Form.Group>
                                    <div className="d-flex flex-column mt-3 mb-2">
                                        <input type="submit" value={t('edit_url.formInputSave')}
                                               className="btn btn-primary"/>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
                <Toaster position="top-right"/>
            </div>
        </>
    );
}

export default EditUrlPage;