import {t} from "i18next";
import {Form} from "react-bootstrap";
import {FormEvent, SetStateAction, useContext, useEffect, useState} from "react";
import toast, {Toaster} from "react-hot-toast";
import DataContext from "../../context/DataContext";
import {Api} from "../../services/api";
import {IAIResponse} from "../../interfaces/IAIResponse";

const ProfilePage = () => {
    const {userPermissions, profile} = useContext(DataContext);

    const [password, setPassword] = useState<string>('');
    const [passwordConfirm, setPasswordConfirm] = useState<string>('');
    const [profileImage, setProfileImage] = useState<File>();

    useEffect(() => {
        const apiUrl = localStorage.getItem('apiUrl');
        if (!apiUrl) {
            localStorage.removeItem('token');
            localStorage.removeItem('user_id');
            window.location.href = '/edit-url';
        }
    }, []);

    useEffect(() => {
        if (!profile.id) {
            localStorage.removeItem('token');
            localStorage.removeItem('user_id');
            window.location.href = '/login';
        }
    }, [profile.id])

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (!form.checkValidity()) {
            toast.error(t('profile.form-error'), {position: "bottom-center"});
            return;
        }

        if (password.length > 0 && password.length < 8) {
            toast.error(t('profile.password-too-short'), { position: "bottom-center" });
            return;
        }

        if (password && (password !== passwordConfirm)) {
            toast.error(t('profile.password-mismatch'), { position: "bottom-center" });
            return;
        }

        const toastId = toast.loading(t('profile.saveToastLoading'), {position: "bottom-center"})
        try {
            const formData = new FormData();
            formData.append("new_password", password);
            if (profileImage) {
                formData.append("profile_image", profileImage);
            }

            Api.raw('account/change-profile', {
                method: 'POST',
                body: formData,
                headers: {}
            }).then(response => {
                // TODO: Ha a profil kép fel lett töltve, akkor frissüljön a navbar-on
                if (response.status === 201) {
                    setTimeout(function () {
                        toast.success(t('profile.saveToastSuccess'), {
                            id: toastId,
                            position: "bottom-center"
                        });

                        if(password.length > 0) {
                            localStorage.removeItem('token');
                            localStorage.removeItem('user_id');
                            setTimeout(function () {
                                window.location.href = '/login';
                            }, 1600)
                        }
                    }, 2000);
                } else {
                    toast.error(t('profile.saveToastError'), {
                        id: toastId,
                        position: "bottom-center"
                    });
                }
            });
        } catch (error) {
            toast.error(t('profile.saveToastError'), {
                id: toastId,
                position: "bottom-center"
            });
        }
    };

    return (
        <>
            <div className="position-relative pb-5 d-flex align-items-center justify-content-center w-100">
                <div className="row w-100">
                    <div className="col-md-12 col-lg-4 mx-auto">
                        <div className="card shadow-sm">
                            <div className="card-header">
                                <h5 className="card-title">{t('profile.profile-update')}</h5>
                            </div>
                            <div className="card-body">
                                {profile.profile_image && <div className="profile-image"><img src={profile.profile_image} /></div>}
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group
                                        className="my-3"
                                        controlId="file_name"
                                        onChange={e => setProfileImage((e.target as HTMLInputElement).files![0])}>
                                        <Form.Label>{t('profile.profile-image')}</Form.Label>
                                        <Form.Control type="file" placeholder={t('profile.profile-image-placeholder')} />
                                    </Form.Group>


                                    <Form.Group className="mb-3" controlId="username">
                                        <Form.Label>{t('profile.new-password')}</Form.Label>
                                        <Form.Control type="password" onChange={(e: {
                                            target: { value: SetStateAction<string>; };
                                        }) => setPassword(e.target.value)}
                                                      value={password}
                                                      aria-describedby="urlHelpBlock"
                                                      />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="username">
                                        <Form.Label>{t('profile.password-confirm')}</Form.Label>
                                        <Form.Control type="password" onChange={(e: {
                                            target: { value: SetStateAction<string>; };
                                        }) => setPasswordConfirm(e.target.value)}
                                                      value={passwordConfirm}
                                                      aria-describedby="urlHelpBlock"
                                                      />
                                    </Form.Group>
                                    <div className="d-flex flex-column mt-3 mb-2">
                                        <input type="submit" value={t('edit_url.formInputSave')}
                                               className="btn btn-primary"/>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
                <Toaster position="top-right"/>
            </div>
        </>
    );
}

export default ProfilePage;