import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import softwareMentorRouter from "softwarementor/services/router";
import router from "services/router";
import {RouterProvider} from "react-router";

const domain = process.env.REACT_APP_SITE;
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <RouterProvider router={router}></RouterProvider>
    </React.StrictMode>
);