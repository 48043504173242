import React, {useEffect, useState} from 'react';
import {t} from "i18next";
import {Accordion, Badge, Button, Card, Modal, Spinner, Table} from "react-bootstrap";
import {IoDocumentsOutline} from "react-icons/io5";
import {BsTrashFill} from "react-icons/bs";
import {IoMdAdd} from "react-icons/io";

import PermissionCheck from "components/permissioncheck/permissioncheck";
import FilterText from "components/filters/FilterText";
import FilterSelect from "components/filters/FilterSelect";
import IAssistant from "interfaces/IAssistant";
import {Api} from "services/api";
import {IAssistantFile} from "interfaces/IAssistantFile";
import FilterDate from "components/filters/FilterDate";
import {TypeChoices} from "components/FileType";
import AccordionCustomToggle from "./AccordionCustomToggle";
import AccordionCustomBodyToggle from "./AccordionCustomBodyToggle";
import toast from "react-hot-toast";
import {shortenFileName} from "../../../services/utils";

interface DocumentsProps {
    assistant: IAssistant;
}

const StateChoices = {
    'true': <BsTrashFill size={24}/>,
    'false': <IoMdAdd size={24}/>,
}

const hasFile = (file: IAssistantFile, assistantFiles: IAssistantFile[]) => {
    const filtered = assistantFiles.filter((filteredFile) => {
        return filteredFile.file_id == file.file_id;
    });
    return filtered.length > 0;
}

const Documents = ({assistant}: DocumentsProps) => {
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [filters, setFilters] = useState<any>({});
    const [files, setFiles] = useState<IAssistantFile[]>([]);
    const [filteredFiles, setFilteredFiles] = useState<IAssistantFile[]>([]);
    const [assistantObject, setAssistantObject] = useState<IAssistant>(assistant);

    const attachFile = async (file: IAssistantFile) => {
        setIsLoading(true);
        await Api.post(`assistant/${assistantObject.id}/attach`, {file_id: file.id})
        loadAssistant().then(() => {
            void loadFiles();
        });
        setIsLoading(false);
        toast.success(t('assistants.document_added'));
    };
    const detachFile = async (file: IAssistantFile) => {
        setIsLoading(true);
        await Api.post(`assistant/${assistantObject.id}/detach`, {file_id: file.file_id})
        loadAssistant().then(() => {
            void loadFiles();
        });
        setIsLoading(false);
        toast.success(t('assistants.document_remove_success'));
    };

    const loadFiles = async () => {
        const response = await Api.get<IAssistantFile[]>('assistant/files');
        setFiles(response);
    }
    const loadAssistant = async () => {
        const response = await Api.get<IAssistant>('assistant/assistant/' + assistant.id);
        setAssistantObject(response);
    }

    useEffect(() => {
        if (showModal) {
            loadAssistant().then(() => {
                void loadFiles();
            });
        }
    }, [showModal]);

    useEffect(() => {
        const filtered = files.filter((file) => {
            let result = true;

            if (typeof filters.title !== 'undefined' && filters.title.length && file.title.toLowerCase().indexOf(filters.title.toLowerCase()) === -1) {
                result = false;
            }

            // Típus szerinti szűrés
            if (typeof filters.type !== 'undefined' && filters.type.length && !filters.type.includes(file.type)) {
                result = false;
            }

            if (typeof filters.operations !== 'undefined' && filters.operations.length && filters.operations.length > 0) {
                const hasPerm = hasFile(file, assistant.files);
                if (
                    (hasPerm && !filters.operations.includes('true')) ||
                    (!hasPerm && !filters.operations.includes('false'))
                ) {
                    result = false;
                }
            }

            return result;
        });

        setFilteredFiles(filtered);
    }, [files, filters]);

    return (
        <PermissionCheck permission={['assistant.view_document']}>
            <Button variant="outline-secondary" size="sm" onClick={e => {
                setShowModal(true)
            }}>
                <div className="d-flex justify-content-start align-items-center gap-2">
                    <IoDocumentsOutline size={18}/>
                    <span>{t("assistants.table.add_documents")}</span>
                    <Badge pill bg="secondary">{assistantObject.files?.length ?? 0}</Badge>
                </div>
            </Button>
            <Modal
                show={showModal}
                className="full-height-modal"
                onHide={() => setShowModal(false)} centered size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("assistants.document_assistant_title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3 px-3 py-2 scroll-container">
                        <div className="scroll-inner">
                            <Accordion defaultActiveKey="-1">
                                <Table responsive="sm" hover className="align-middle">
                                    <thead>
                                    <tr>
                                        <th>
                                            {t("assistants.document.table.created_at")}
                                            <FilterDate filters={filters} setFilters={setFilters} field="created_at"
                                                        fieldLabel="assistants.document.table.created_at"></FilterDate>
                                        </th>
                                        <th>
                                            {t("assistants.document.table.title")}
                                            <FilterText filters={filters} setFilters={setFilters} field="title"
                                                        fieldLabel="assistants.document.table.title"></FilterText>
                                        </th>
                                        <th>
                                            {t("assistants.document.table.type")}
                                            <FilterSelect field="type" fieldLabel={t("group.operations")}
                                                          choices={TypeChoices} filters={filters}
                                                          setFilters={setFilters}/>
                                        </th>
                                        <th className="text-end">
                                            {t("group.operations")}
                                            <FilterSelect field="operations" fieldLabel={t("group.operations")}
                                                          choices={StateChoices} filters={filters}
                                                          setFilters={setFilters}/>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {filteredFiles.length === 0 && <tr>
                                        <td colSpan={4}
                                            className="text-center">{t('assistants.document.noPermission')}</td>
                                    </tr>}
                                    {filteredFiles.map((file, index) => <tr key={file.id}>
                                        <td>
                                            {file.created_at}
                                        </td>
                                        <td>
                                            <AccordionCustomToggle eventKey={index.toString()}>
                                                <span title={file.title}>{shortenFileName(file.title)}</span>
                                                <Accordion.Item eventKey={index.toString()}
                                                                className='border-0 p-0'>
                                                    <Accordion.Body className='p-0'>
                                                        <AccordionCustomBodyToggle
                                                            eventKey={index.toString()}
                                                        >
                                                            <Card
                                                                className='shadow-sm mt-2 accordian-card'>
                                                                <Card.Body className='py-2'>
                                                                        <pre
                                                                            style={{'whiteSpace': 'pre-wrap'}}>{file.description}</pre>
                                                                </Card.Body>
                                                            </Card>
                                                        </AccordionCustomBodyToggle>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </AccordionCustomToggle>
                                        </td>
                                        <td>
                                            {TypeChoices[file.type]}
                                        </td>
                                        <td className="text-end">
                                            {isLoading && <Spinner animation="border" size="sm" variant="primary" className="d-inline-block"/>}
                                            {!isLoading && (hasFile(file, assistantObject.files) ?
                                                <Button onClick={() => detachFile(file)} variant="outline-danger">
                                                    <BsTrashFill/>
                                                </Button> :
                                                <Button onClick={() => attachFile(file)} variant="outline-secondary">
                                                    <IoMdAdd/>
                                                </Button>
                                            )}
                                        </td>
                                    </tr>)}
                                    </tbody>
                                </Table>
                            </Accordion>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </PermissionCheck>
    );
};

export default Documents;