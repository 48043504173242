export function token(token: number) {
    return new Intl.NumberFormat(undefined, {

    }).format(token);
}

export const bytesToSize = (bytes: number, seperator: string = "") => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    if (bytes == 0) return 'n/a'
    const i = parseInt(String(Math.floor(Math.log(bytes) / Math.log(1024))), 10)
    if (i === 0) return `${bytes}${seperator}${sizes[i]}`
    return `${(bytes / (1024 ** i)).toFixed(1)}${seperator}${sizes[i]}`
}

export const shortenFileName = (fileName:string, maxLength = 40) => {
    if (fileName.length <= maxLength) return fileName;

    const parts = fileName.split('.');
    const extension = parts.length > 1 ? '.' + parts.pop() : ''; // Kiterjesztés leválasztása (ha van)
    const nameWithoutExt = parts.join('.'); // Fájlnév kiterjesztés nélkül

    if (nameWithoutExt.includes('_')) {
        // Ha van alulvonás, akkor az előző módszer szerint rövidítünk
        const words = nameWithoutExt.split('_');
        let shortened = words[0]; // Első szó mindig marad

        for (let i = 1; i < words.length; i++) {
            if ((shortened + '_' + words[i] + '...').length + extension.length > maxLength) {
                return `${shortened}...${extension}`;
            }
            shortened += `_${words[i]}`;
        }

        return `${shortened}${extension}`;
    } else {
        // Ha nincs alulvonás, akkor karakter alapon rövidítünk
        const visibleChars = maxLength - 3 - extension.length; // Három pont helye és a kiterjesztés
        const shortened = nameWithoutExt.substring(0, visibleChars);
        return `${shortened}...${extension}`;
    }
};
