import AssistantStat from "./components/AssistantStat";
import PlatformStat from "./components/PlatformStat";
import AssistanMonthlytStat from "./components/AssistantMonthlyStat";
import AssistantGlobalStat from "./components/AssistantGlobalStat";
import OffcanvasChat from "./components/OffcanvasChat";
import {BiBot} from "react-icons/bi";
import {t} from "i18next";
import {Button} from "react-bootstrap";
import {useEffect, useState} from "react";
import {Api} from "../../services/api";

const StatPage = () => {
    const [show, setShow] = useState(false);
    const [hasChatbot, setHasChatbot] = useState<boolean | null>(false);
    const [chatbotID, setChatbotID] = useState(0);

    useEffect(() => {
        Api.get<any>('chatbot/get-stat-chat-assistant').then((response) => {
            const apiData = response;
            console.log(apiData);

            if (apiData.chatbot === null || apiData.assistant_id === null) {
                setHasChatbot(false);
                localStorage.removeItem('assistant_id');
                localStorage.removeItem('thread_id');
                localStorage.removeItem('assistant_profile_image');
            } else {
                if (!!apiData.chatbot && !!apiData.assistant_id) {
                    setHasChatbot(true);
                    setChatbotID(apiData.chatbot_id)
                    localStorage.setItem('assistant_id', apiData.assistant_id);
                    if (!!apiData.thread_id) {
                        localStorage.setItem('thread_id', apiData.thread_id);
                    }
                    localStorage.removeItem('assistant_profile_image');
                }
            }
        }).catch((error) => {
            console.error(error)
        })
    }, []);

    const handleShow = () => setShow(!show);

    return (
        <>
            <div className='m-2'>
                <div style={{'display': hasChatbot ? 'block' : 'none'}}>
                    <Button>
                        <div className="d-flex justify-content-start align-items-center"
                             onClick={() => handleShow()}>
                            <BiBot size={18}/>
                            <span className="ms-1">{t('stat.botName')}</span>
                        </div>
                    </Button>
                </div>
                <div className="row mt-2">
                    <div className="col-12">
                        <AssistantStat/>
                    </div>
                </div>
                <div className="row g-2 mt-3">
                    <div className="col-6">
                        <AssistantGlobalStat/>
                    </div>
                    <div className="col-6">
                        <PlatformStat/>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12">
                        <AssistanMonthlytStat/>
                    </div>
                </div>
            </div>
            {hasChatbot && <OffcanvasChat show={show} handleClose={handleShow} chatbotID={chatbotID}/>}
        </>
    );
}

export default StatPage;