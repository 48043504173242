import {BiTrash} from "react-icons/bi";
import {Button, Tooltip, OverlayTrigger} from "react-bootstrap";
import toast, {Toast} from "react-hot-toast";
import {t} from "i18next";
import React, {useContext} from "react";
import ChatContext from "../context/ChatContext";
import { FaPlus } from "react-icons/fa";

const History = () => {

    const {setThreadId} = useContext(ChatContext);

    const clearHistory = (tt: Toast) => {
        toast.dismiss(tt.id);
        setThreadId('');
        localStorage.removeItem('thread_id')
    };

    const handleClearHistory = () => {
        toast((tt: Toast) => (
            <div>
                <div className="mb-3 fw-bold">{t("chat.confirm-start-new-chat")}</div>
                <div>
                    <Button
                        variant="primary"
                        size="sm"
                        onClick={() => clearHistory(tt)}
                    >
                        {t("chat.yes")}
                    </Button>
                    <Button
                        className="ms-3"
                        variant="secondary"
                        size="sm"
                        onClick={() => toast.dismiss(tt.id)}
                    >
                        {t("chat.cancel")}
                    </Button>
                </div>
            </div>
        ));
    };

    return (
        <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="button-tooltip-2">
                <div>{t('chat.start-new-chat')}</div>
            </Tooltip>}
        >
            <Button
                variant="dark"
                type="submit"
                onClick={handleClearHistory}
            >
                <FaPlus />
            </Button>
        </OverlayTrigger>
    );
}
export default History;