import React, {Dispatch, SetStateAction, useState, useEffect} from 'react';
import {t} from "i18next";
import {Button, Modal} from "react-bootstrap";

interface FilterProps {
    choices: { [key: string]: JSX.Element },
    filters: any,
    setFilters: Dispatch<SetStateAction<any>>,
    field: string,
    fieldLabel?: string
}

const FilterSelect = ({choices, filters, setFilters, field, fieldLabel}: FilterProps) => {
    const [showModal, setShowModal] = useState<boolean>(false);

    // Helper function to handle picking or unpicking choices
    const toggleChoice = (choice: string) => {
        setFilters((prevState: any) => {
            const current = prevState[field] || []; // If undefined, default to empty array
            const newPicked = current.includes(choice)
                ? current.filter((item: string) => item !== choice)  // Remove choice if already picked
                : [...current, choice];  // Add choice if not already picked
            return {...prevState, [field]: newPicked};
        });
    };

    return (
        <div>
            {(!filters[field] || filters[field].length === 0) && (
                <button  // `small` helyett `button` elem használata
                    className="text-muted cursor-pointer text-nowrap btn btn-link p-0"  // Stílusok hozzáadása a `small` megjelenéshez hasonlóan
                    onClick={() => setShowModal(true)}
                >
                    <small>{t('filter.empty')}</small>
                </button>
            )}

            {filters[field] && filters[field].length > 0 && (
                <div>
                    <button
                        className="btn btn-link p-0"
                        onClick={() => setShowModal(true)}
                    >
                        <small className="text-muted text-nowrap">{t('filter.filtered')}</small>
                        <div className="d-flex gap-1">
                            {filters[field].map((item: string) => choices[item])}
                        </div>
                    </button>
                </div>
            )}

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('filter.modalTitle')}: {fieldLabel}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex justify-content-between mb-4">
                        <Button variant="secondary" size="sm" className="ms-2" onClick={() => setFilters((prevState: any) => ({
                            ...prevState,
                            [field]: []
                        }))}>
                            {t('filter.clear')}
                        </Button>
                        <Button variant="secondary" size="sm" className="ms-2" onClick={() => setFilters([])}>
                            {t('filter.clearAll')}
                        </Button>
                    </div>
                    <div className="d-flex justify-content-evenly">
                        {Object.keys(choices).map(choice => (
                            <button  // A `div` helyett `button` elem használata
                                className={'px-4 py-2 btn btn-link ' + (filters[field] && filters[field].includes(choice) ? 'border rounded' : '')}
                                key={choice}
                                onClick={() => toggleChoice(choice)}
                            >
                                {choices[choice]} {/* A `choices[choice]` JSX.Element változatlanul megjeleníthető */}
                            </button>
                        ))}
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default FilterSelect;
