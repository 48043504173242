import {useState} from "react";
import {IProfile} from "../interfaces/IProfile";
import {IPermission} from "../interfaces/IPermission";

export const useInitialData = () => {
    const [profile, setProfile] = useState<IProfile>({} as IProfile);
    const [userPermissions, setUserPermissions] = useState<IPermission>({} as IPermission);

     return {
        profile,
        setProfile,
        userPermissions,
        setUserPermissions,
    }
}
