import React, {useState} from 'react';
import IFile from "interfaces/IFile";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {Button, Modal, Tooltip} from "react-bootstrap";
import {t} from "i18next";
import {BsTrashFill} from "react-icons/bs";
import PermissionCheck from "components/permissioncheck/permissioncheck";
import {Api} from "services/api";

interface DeleteModalProps {
    file: IFile,
    getList: () => Promise<void>
}

const DeleteModal = ({file, getList}: DeleteModalProps) => {
    const [showModal, setShowModal] = useState<boolean>(false);

    const deleteSubtitle = () => {
        Api.get(`file/${file.id}/delete`).then(response => {
            setShowModal(false)
            void getList();
        })
    };
    return (
        <>
            <PermissionCheck permission="file.delete">
                <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="button-tooltip-2">
                        <div>{t('file.delete')}</div>
                    </Tooltip>}
                >
                    <Button variant="outline-danger" size="sm"
                            onClick={e => setShowModal(true)}>
                        <BsTrashFill size={18}/>
                    </Button>
                </OverlayTrigger>

                <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("file.confirmDeleteTitle")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {t("file.confirmDelete")}: <br/>
                        <b>{file.title}?</b>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={deleteSubtitle}>
                            {t('file.yes')}
                        </Button>
                        <Button variant="primary" onClick={e => setShowModal(false)}>
                            {t('file.no')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </PermissionCheck>
        </>
    );
};

export default DeleteModal;