import {t} from "i18next";
import {Container, Form} from "react-bootstrap";
import {FormEvent, useContext, useState} from "react";
import {Api} from "../../services/api";
import {ILoginToken} from "../../interfaces/ILoginToken";
import toast, {Toaster} from "react-hot-toast";
import {Navigate, useNavigate} from "react-router-dom";
import DataContext from "../../context/DataContext";
import {Link} from "react-router-dom";

const LoginPage = () => {

    const [validated, setValidated] = useState(false);
    const [username, setUserName] = useState<string>();
    const [password, setPassword] = useState<string>();
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const apiUrl = localStorage.getItem('apiUrl');

    const {setProfile} = useContext(DataContext);

    if (!!token) {
        return <Navigate to="/" replace={true}/>;
    }

    if (!apiUrl) {
        return <Navigate to="/edit-url" replace={true}/>;
    }

    const submitLogin = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        const data = {
            username: username,
            password: password,
        }


        Api.post<ILoginToken>('account/login', data)
            .then(response => {
                localStorage.setItem('token', response.token);
                localStorage.setItem('user_id', String(response.user.id));
                setProfile(response.user);
                //navigate('/')
                window.location.href = '/';
            })
            .catch(error => {
                let message = "";
                if (typeof error.messages !== 'undefined') {
                    message = error.messages.join('<br />');
                }
                toast.error(message);
            });
    }

    return (
        <Container className="position-relative pb-5 d-flex align-items-center justify-content-center">
            <Form noValidate validated={validated} onSubmit={submitLogin}>
                <Form.Group className="mb-3" controlId="username">
                    <Form.Label>{t('login.username')}</Form.Label>
                    <Form.Control type="text" onChange={e => setUserName(e.target.value)}/>
                </Form.Group>
                <Form.Group className="mb-1" controlId="password">
                    <Form.Label>{t('login.password')}</Form.Label>
                    <Form.Control type="password" onChange={e => setPassword(e.target.value)}/>
                </Form.Group>
                <div className="pb-3">
                    <Link to="/reset-password">
                        {t('login.reset-password')}
                    </Link>
                </div>
                <div className="d-flex flex-column mb-2">
                    <input type="submit" value={t('login.submit')} className="btn btn-primary py-1"/>
                </div>
            </Form>
            <Toaster position="top-right"/>
        </Container>
    );
}

export default LoginPage;
