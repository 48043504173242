import React from 'react';
import {FiCheck} from "react-icons/fi";
import {t} from "i18next";
import {Badge, Spinner} from "react-bootstrap";
import IFile from "interfaces/IFile";
import {MdCancel} from "react-icons/md";

const StatusBadge = ({file}: {file: IFile}) => {
    return (
        <>
            <Badge pill bg="success" className="d-inline-flex align-items-center">
                {file.status == 'completed' && <FiCheck className="me-2"/>}
                {file.status == 'failed' && <MdCancel className="me-2"/>}
                {file.status != 'completed' && file.status !== 'failed' && <Spinner
                    className="statusSpinner me-2"
                    animation="border"
                    variant="light"/>
                }
                {t('file.status.' + file.status)}
            </Badge>
        </>
    );
};

export default StatusBadge;