import {HiOutlineVolumeUp, HiStop} from "react-icons/hi";
import React, {useEffect, useState} from "react";
import Spinner from 'react-bootstrap/Spinner';
import TooltipLayer from "../../../../components/tooltip/tooltip";
import {t} from "i18next";
import {Api} from "../../../../services/api";

const MessagePlayAudioBtn = ({content, assistant_id}: { content: string, assistant_id: string }) => {
    const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (audio) {
            audio.onended = () => setIsPlaying(false);
        }
    }, [audio]);

    const extractTextFromHTML = (htmlString: string) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');
        return doc.body.textContent || "";
    };

    const handlePlay = async (text: string) => {
        if (isPlaying) {
            audio?.pause();
            setIsPlaying(false);
        } else {
            if (!audio) {
                const data = {
                    assistant_id: assistant_id,
                    text: extractTextFromHTML(text)
                };

                try {
                    setIsLoading(true);
                    const requestOptions = {
                        method: 'POST',
                        body: JSON.stringify(data),
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    };
                    await Api.raw('/chat/audio', requestOptions).then(async (response: any) => {
                        const audioBlob = await response.blob();
                        const audioUrl = URL.createObjectURL(audioBlob);
                        const newAudio = new Audio(audioUrl);
                        setAudio(newAudio);
                        await newAudio.play();
                        setIsPlaying(true);
                    });
                } catch (e) {
                    console.error(e);
                } finally {
                    setIsLoading(false);
                }

            } else {
                await audio.play();
                setIsPlaying(true);
            }
        }
    };

    return (
        <TooltipLayer title={isPlaying ? t("message_buttons.play_off") : t("message_buttons.play_on")}
                      id="message-btn-audio">
            <button
                type="button"
                className="btn btn-link btn-sm d-flex justify-content-center align-items-center"
                onClick={() => handlePlay(content)}
                disabled={isLoading}  // Disable the button while loading
            >
                {isLoading ? (
                    <Spinner animation="border" role="status" size="sm">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                ) : isPlaying ? (
                    <HiStop size={18}/>
                ) : (
                    <HiOutlineVolumeUp size={18}/>
                )}
            </button>
        </TooltipLayer>
    );
};

export default MessagePlayAudioBtn;
