import React from 'react';
import IFile from "interfaces/IFile";
import {BsCameraVideoFill, BsFile, BsFiletypeDoc, BsFiletypeRaw, BsFillVolumeUpFill, BsYoutube} from "react-icons/bs";
import {IAssistantFile} from "../interfaces/IAssistantFile";

export const TypeChoices = {
    'audio': <BsFillVolumeUpFill size={24}/>,
    'video': <BsCameraVideoFill size={24}/>,
    'youtube': <BsYoutube size={24}/>,
    'document': <BsFiletypeDoc size={24}/>,
}

const FileType = ({file}: {file: IAssistantFile | IFile}) => {
    return (
        <>
            {TypeChoices[file.type]}
        </>
    );
};

export default FileType;