import {useContext, useEffect} from "react";
import {Navigate, useNavigate} from "react-router-dom";
import {Api} from "../../services/api";
import DataContext from "../../context/DataContext";
import {IProfile} from "../../interfaces/IProfile";

const LogoutPage = () => {
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const {setProfile} = useContext(DataContext);

    useEffect(() => {
        Api.get<any>('account/logout')
            .then(response => {
                localStorage.removeItem('token');
                localStorage.removeItem('user_id');

                // Remove the chat stuff
                localStorage.removeItem('history_id');
                localStorage.removeItem('doc_id');
                setProfile({} as IProfile);
                navigate('/');
            })
    })

    if (!!token) {
        return <Navigate to="/" replace={true} />;
    }
    return (<>Loading...</>);
}

export default LogoutPage;