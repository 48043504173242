import {t} from "i18next";
import {Form} from "react-bootstrap";
import {FormEvent, SetStateAction, useContext, useEffect, useState} from "react";
import toast, {Toaster} from "react-hot-toast";
import DataContext from "../../context/DataContext";
import {Api} from "../../services/api";
import {IAIResponse} from "../../interfaces/IAIResponse";

const ProfilePage = () => {
    const {userPermissions, profile} = useContext(DataContext);

    const [emailAddress, setEmailAddress] = useState<string>('');

    useEffect(() => {
        const apiUrl = localStorage.getItem('apiUrl');
        if (!apiUrl) {
            localStorage.removeItem('token');
            localStorage.removeItem('user_id');
            window.location.href = '/edit-url';
        }
    }, []);

    useEffect(() => {
        if (profile.id) {
            localStorage.removeItem('token');
            localStorage.removeItem('user_id');
            window.location.href = '/login';
        }
    }, [profile.id])

    const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        const confirmed = window.confirm(t('profile.reset.remove_confirm'));
        if (!confirmed) return;

        if (!form.checkValidity()) {
            toast.error(t('profile.form-error'), {position: "bottom-center"});
            return;
        }

        const toastId = toast.loading(t('profile.reset.saveToastLoading'), {position: "bottom-center"})
        try {
            const formdata = new FormData();
            formdata.append("email", emailAddress);

            Api.raw('account/reset-password', {
                method: 'POST',
                body: formdata,
                headers: {}
            }).then(response => {
                if (response.status === 201) {
                    setTimeout(function () {
                        toast.success(t('profile.reset.saveToastResetSuccess'), {
                            id: toastId,
                            position: "bottom-center"
                        });
                        localStorage.removeItem('token');
                        localStorage.removeItem('user_id');
                        setTimeout(function () {
                            window.location.href = '/login';
                        }, 1600)
                    }, 2000);
                } else if (response.status === 404) {
                    toast.error(t('profile.reset.notFoundError'), {
                        id: toastId,
                        position: "bottom-center"
                    });
                } else {
                    toast.error(t('profile.reset.saveToastError'), {
                        id: toastId,
                        position: "bottom-center"
                    });
                }
            });
        } catch (error) {
            toast.error(t('profile.saveToastError'), {
                id: toastId,
                position: "bottom-center"
            });
        }
    };

    return (
        <>
            <div className="position-relative pb-5 d-flex align-items-center justify-content-center w-100">
                <div className="row w-100">
                    <div className="col-md-12 col-lg-4 mx-auto">
                        <div className="card shadow-sm" style={{maxHeight: "400px"}}>
                            <div className="card-header">
                                <h5 className="card-title">{t('login.reset-password')}</h5>
                            </div>
                            <div className="card-body">
                                <Form onSubmit={handleFormSubmit}>
                                    <Form.Group className="mb-3" controlId="username">
                                        <Form.Label>{t('login.emailAddress')}</Form.Label>
                                        <Form.Control type="text" onChange={(e: {
                                            target: { value: SetStateAction<string>; };
                                        }) => setEmailAddress(e.target.value)}
                                                      value={emailAddress}
                                                      aria-describedby="urlHelpBlock"
                                                      required/>
                                    </Form.Group>
                                    <div className="d-flex flex-column mt-3 mb-2">
                                        <input type="submit" value={t('profile.reset.remove_password_send')}
                                               className="btn btn-primary"/>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
                <Toaster position="top-right"/>
            </div>
        </>
    );
}

export default ProfilePage;