import React, {useContext} from "react";
import {AccordionContext, useAccordionButton} from "react-bootstrap";

interface CustomToggleProps {
    children: React.ReactNode;
    eventKey: string;
    callback?: (eventKey: string) => void;
}

const AccordionCustomToggle = ({children, eventKey, callback}: CustomToggleProps) => {
    const {activeEventKey} = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <div onClick={decoratedOnClick} className={`accordion-title ${isCurrentEventKey ? 'active' : ''}`}>
            {children}
        </div>
    );
}

export default AccordionCustomToggle;