import React, {useEffect, useState} from 'react';
import {Stack, Table} from "react-bootstrap";

import {t} from "i18next";

import PermissionCheck from "components/permissioncheck/permissioncheck";
import FilterText from "components/filters/FilterText";
import {IGroup} from "interfaces/IGroup";
import {usePermissions} from "hooks/usePermissions";
import {Api} from "services/api";

import Group from "./components/Group";
import Users from "./components/Users";
import Permissions from "./components/Permissions";

const GroupPage = () => {
    const {checkPermissions} = usePermissions();
    const [isLoading, setIsLoading] = useState(false);
    const [groups, setGroups] = useState<IGroup[]>([]);
    const [filteredGroups, setFilteredGroups] = useState<IGroup[]>([]);
    const [filters, setFilters] = useState<any>({});

    const getGroups = async () => {
        const response = await Api.get<IGroup[]>('groups/')
        setGroups(response);
    }

    useEffect(() => {
        checkPermissions([
            'group.view_group',
            'group.add_group',
            'group.change_group',
            'group.show_users',
            'group.show_permission'
        ]);
        void getGroups();
    }, []);

    useEffect(() => {
        const filtered = groups.filter((group) => {
            let result = true;

            // Cím szerinti szűrés
            if (typeof filters.name !== 'undefined' && filters.name.length && group.name.toLowerCase().indexOf(filters.name.toLowerCase()) === -1) {
                result = false;
            }

            return result;
        });

        setFilteredGroups(filtered);
    }, [filters, groups]);

    return (
        <PermissionCheck permission={['group.view_group']}>
            <div className="py-3 mt-2">
                <Group getGroups={getGroups}></Group>
            </div>
            <div className="card shadow-sm mb-3 px-3 py-2">

                <Table responsive="sm" hover className="align-middle">
                    <thead>
                    <tr>
                        <th>
                            {t("group.table.name")}
                            <FilterText filters={filters} setFilters={setFilters} field="name" fieldLabel="group.table.name"></FilterText>
                        </th>
                        <th className="text-end">
                            {t("assistants.table.operations")}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredGroups.length > 0 && !isLoading && filteredGroups.map((group: IGroup) => (
                        <tr key={group.id}>
                            <td>
                                {group.name}
                            </td>
                            <td className="text-end">
                                <Stack direction="horizontal" gap={2} className="justify-content-end">
                                    <Group group={group} getGroups={getGroups} />
                                    <Permissions group={group} />
                                    <Users group={group} />
                                </Stack>
                            </td>
                        </tr>
                    ))}
                    {filteredGroups.length === 0 && !isLoading && <tr >
                        <td colSpan={6} className="text-center">{t('group.noGroups')}</td>
                    </tr>}
                    </tbody>
                </Table>
            </div>
        </PermissionCheck>
    );
};

export default GroupPage;