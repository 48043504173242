import {useState} from "react";
import {IMessage} from "interfaces/IMessage";

export const useInitialData = () => {
    const [assistantId, setAssistantId] = useState<string>(() => {
        const assistant_id = localStorage.getItem('assistant_id');
        if (assistant_id == null) {
            return '';
        }
        return assistant_id;
    });

    const [message, setMessage] = useState<string>('');
    const [messages, setMessages] = useState<IMessage[]>([]);
    const [threadId, setThreadId] = useState<string>(() => {
        const thread_id = localStorage.getItem('thread_id');
        if (thread_id == null) {
            return '';
        }
        return thread_id;
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [useAudio, setUseAudio] = useState<boolean>(false);
    const [audioState, setAudioState] = useState<string>('disabled');
    const [audioSrcs, setAudioSrcs] = useState<string[]>([]);

    const [selectedMessage, setSelectedMessage] = useState<string>('');

    return {
        message, setMessage,
        messages, setMessages,
        threadId, setThreadId,
        isLoading, setIsLoading,
        useAudio, setUseAudio,
        audioState, setAudioState,
        audioSrcs, setAudioSrcs,
        assistantId, setAssistantId,
        selectedMessage, setSelectedMessage
    }
}