import {BiBot} from "react-icons/bi";
import {Button, Tooltip, OverlayTrigger} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import toast, {Toast} from "react-hot-toast";
import {t} from "i18next";
import React from "react";

const Bots = () => {
    const navigate = useNavigate();

    const redirectToBots = (tt: Toast) => {
        toast.dismiss(tt.id);
        sessionStorage.setItem("tabKey", "assistants");
        navigate('/')
    }

    const handleBots = () => {
        toast((tt: Toast) => (
            <div>
                <div className="mb-3 fw-bold">{t("chat.confirm-bots-redirect")}</div>
                <div>
                    <Button
                        variant="primary"
                        size="sm"
                        onClick={() => redirectToBots(tt)}
                    >
                        {t("chat.yes")}
                    </Button>
                    <Button
                        className="ms-3"
                        variant="secondary"
                        size="sm"
                        onClick={() => toast.dismiss(tt.id)}
                    >
                        {t("chat.cancel")}
                    </Button>
                </div>
            </div>
        ));
    }

    return (<>
        <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="button-tooltip-2">
                <div>{t('chat.leave-chat')}</div>
            </Tooltip>}
        >
            <Button
                variant="dark"
                type="submit"
                onClick={handleBots}
            >
                <BiBot/>
            </Button>
        </OverlayTrigger>
    </>)
}
export default Bots;