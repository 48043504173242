import React, {useEffect, useState} from 'react';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {Button, Form, Modal, Spinner, Tab, Tabs, Tooltip} from "react-bootstrap";
import {t} from "i18next";
import {BsFileEarmarkTextFill} from "react-icons/bs";
import IFile from "interfaces/IFile";
import SubtitleEdit from "components/subtitle_edit/subtitle_edit";
import {FaPencilRuler, FaPlay} from "react-icons/fa";
import PermissionCheck from "../../../components/permissioncheck/permissioncheck";
import {LiaExternalLinkAltSolid} from "react-icons/lia";
import "./ContentModal.css";
import {Api} from "../../../services/api";

const ContentModal = ({file, getList}: { file: IFile, getList: () => Promise<void> }) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [title, setTitle] = useState<string>(file.title);
    const [content, setContent] = useState<string>(file.content);
    const [titleSaveLoading, setTitleSaveLoading] = useState<boolean>(false);
    const [contentSaveLoading, setContentSaveLoading] = useState<boolean>(false);

    function srtText(srt: string): string {
        // Felosztjuk a szöveget blokkokra
        const blocks = srt.split('\n\n');
        const result: string[] = [];

        // Minden blokkot feldolgozunk
        for (const block of blocks) {
            const lines = block.trim().split('\n');
            // Ha a blokk legalább 3 sorból áll, akkor az első két sort kihagyjuk
            if (lines.length >= 3) {
                const text = lines.slice(2).join(' ');
                result.push(text);
            }
        }

        // Az eredményeket egyesítjük két új sorral elválasztva
        return result.join('\n\n');
    }


    const handleTitleSave = () => {
        Api.post('file/' + file.id, {
            title: title,
        }).then((response: any) => {
            file.title = title;
            setIsDirty(true);
            setEditMode(false);
        })
    };
    const handleContentSave = () => {
        Api.post('file/' + file.id, {
            content: content,
        }).then((response: any) => {
            file.content = content;
            setIsDirty(true);
            setEditMode(false);
        })
    };

    useEffect(() => {
        if (!showModal && isDirty) {
            void getList();
        }
    }, [showModal, isDirty]);

    return <>
        {file.content &&
            <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip-2">
                    <div>{t('file.contentModal.content')}</div>
                </Tooltip>}
            >
                <Button variant="outline-secondary"
                        size="sm"
                        onClick={() => {
                            setShowModal(true)
                        }
                        }>
                    <BsFileEarmarkTextFill size={18}/>
                </Button>
            </OverlayTrigger>
        }


        <Modal size="lg"
               show={showModal}
               onHide={() => setShowModal(false)}
               className="full-height-modal"
               centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {t("file.contentModal.content")}: {file.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex justify-content-between">
                    <PermissionCheck permission="file.change_file" message=''>
                        <Button variant="secondary"
                                size="sm"
                                className="mb-3"
                                onClick={() => {
                                    setEditMode(prevState => !prevState);
                                }}>
                            {!editMode && <><FaPencilRuler size={16}/> {t("file.contentModal.edit")}</>}
                            {editMode && <><FaPencilRuler size={16}/> {t("file.contentModal.cancel")}</>}
                        </Button>
                    </PermissionCheck>
                    <Button variant="primary" size="sm" className="mb-3" href={file.playUrl}
                            target="_blank">
                        <FaPlay size={18}/> {t("file.contentModal.playAudioBlank")} <LiaExternalLinkAltSolid/>
                    </Button>
                </div>
                {!editMode && <Tabs
                    defaultActiveKey="contentTab01"
                    id="uncontrolled-tab-01"
                    className="mb-3"
                >
                    <Tab eventKey="contentTab01" title={t('file.contentModal.content')}>
                        <pre style={{'whiteSpace': 'pre-wrap'}} className="px-3">{srtText(file.content)}</pre>
                    </Tab>
                    <Tab eventKey="contentTab02" title={t('file.contentModal.content_srt')}>
                        <pre className="px-3">{file.content}</pre>
                    </Tab>
                </Tabs>}
                {editMode && <Tabs
                    defaultActiveKey="tab01"
                    id="uncontrolled-tab-02"
                    className="mb-3"
                >
                    <Tab eventKey="tab01" title={t("file.contentModal.editTitle")}>
                        <Form.Control
                            as="textarea"
                            style={{height: '100px'}}
                            placeholder={t('file.contentModal.content')}
                            aria-describedby="messageHelpBlock"
                            onChange={(e) => {
                                setTitle(e.target.value);
                            }}
                            value={title}
                            required={true}
                        />
                        <Button variant="primary" size="sm" className="mt-3 w-100 text-center"
                                onClick={handleTitleSave}>
                            {titleSaveLoading && <Spinner animation="border" variant="light" style={{
                                "width": "15px",
                                "height": "15px"
                            }}/>} {t('file.contentModal.saveTitle')}
                        </Button>
                    </Tab>
                    <Tab eventKey="tab02" title={t("file.contentModal.editContent")}>
                        <Form.Control
                            as="textarea"
                            style={{height: '450px'}}
                            placeholder={t('file.contentModal.content')}
                            aria-describedby="messageHelpBlock"
                            onChange={(e) => {
                                setContent(e.target.value);
                            }}
                            value={content}
                        />
                        <Button variant="primary" size="sm" className="mt-3 w-100 text-center"
                                onClick={handleContentSave}>
                            {contentSaveLoading && <Spinner animation="border" variant="light" style={{
                                "width": "15px",
                                "height": "15px"
                            }}/>} {t('file.contentModal.saveContent')}
                        </Button>
                    </Tab>
                </Tabs>}
            </Modal.Body>
        </Modal>
    </>
        ;
};

export default ContentModal;