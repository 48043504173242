const baseUrl = localStorage.getItem('apiUrl');

const apiCall = (url: string, init?: any) => {
   return new Promise<Response>((resolve, reject) => {
      let call: Promise<Response>;
      if (typeof init !== 'undefined') {
         call = fetch(baseUrl + url, init);
      } else {
         call = fetch(baseUrl + url);
      }
      return call
          .then(response => {
             resolve(response);
          })
          .catch((reason: any) => {
             console.log(reason);
             reject(reason);
          });
   });
};

export class Api {

   static raw<T>(url: string, init?: any) {
      const token = localStorage.getItem('token');
      if (!!token) {
         if (typeof init == 'undefined') {
            init = {};
         }
         if (typeof init.headers == 'undefined') {
            init.headers = {
               "Content-Type": "application/json",
            };
         }
         init.headers["Authorization"] = token;
      }
      return apiCall(url, init);
   }

   static parseRaw<T>(url: string, init?: any): Promise<T> {
      return new Promise<T>((resolve, reject) => {
         this.raw<T>(url, init).then(async (response) => {
            if (!response.ok) {
               if (response.status === 422) {
                  const json = await response.json();
                  return reject(json);
               }
               if (response.status === 401) {
                  const json = await response.json();
                  if (json.message === "Unauthenticated.") {
                     localStorage.removeItem('token');
                  }
                  return reject(json);
               } else {
                  return reject({
                     response
                  });
               }
            }

            if (response.status === 204) {
               // Ha a státuszkód 204, nincs tartalom, amit JSON-ként lehetne parsálni
               return resolve(null as any); // Visszaadhatsz nullt vagy egy üres objektumot, ahogy szeretnéd kezelni
            }

            const json = await response.json();
            resolve(json as T);
         }).catch((reason) => {
            console.log(reason);
            reject(reason);
         });
      });
   }


   static get<T>(url: string, data?: any): Promise<T> {
      let init: RequestInit = {};
      if (typeof data !== 'undefined') {
         init = {
            body: JSON.stringify(data)
         };
      }
      return this.parseRaw<T>(url, init);
   }

   static post<T>(url: string, data?: any): Promise<T> {
      return this.requestWithBody<T>('POST', url, data);
   }

   static delete<T>(url: string, data?: any): Promise<T> {
      return this.requestWithBody<T>('DELETE', url, data);
   }

   static put<T>(url: string, data?: any): Promise<T> {
      return this.requestWithBody<T>('PUT', url, data);
   }

   static patch<T>(url: string, data?: any): Promise<T> {
      return this.requestWithBody<T>('PATCH', url, data);
   }

   private static requestWithBody<T>(method: string, url: string, data?: any): Promise<T> {
      const init: RequestInit = {
         method,
         headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
         },
      };
      if (typeof data !== 'undefined') {
         init.body = JSON.stringify(data);
      }
      return this.parseRaw<T>(url, init);
   }
}
