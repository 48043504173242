import {useEffect, useState, useRef} from "react";
import {Chart} from "react-google-charts";
import {Api} from "../../../services/api";
import {t} from "i18next";
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import {AssistantUsage} from "./AssistantMonthlyStat";

const AssistantStat = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<(string | number)[][]>([
        [t("stat.assistants"), t("stat.usage")],
    ]);
    const [originalData, setOriginalData] = useState<(string | number)[][]>([
        [t("stat.assistants"), t("stat.usage")],
    ]);

    const [selectPeriod, setSelectPeriod] = useState<number>(1);
    const [chartEvents, setChartEvents] = useState<any>([]);
    const chartRef = useRef(null);
    const [selectedLine, setSelectedLine] = useState<number | null>(null);

    const options = {
        backgroundColor: 'transparent',
        curveType: "function",
        legend: {position: "top"},
        vAxis: {
            title: t('stat.interaction'),
        },
        lineWidth: 3,
        axes: {
          x: {
            0: {side: 'top'}
          }
        }
    };

    const getStat = () => {
        try {
            setLoading(true);
            Api.get<any>(`stats/assistant-usage?date_filter=${selectPeriod}`).then((response) => {
                const apiData = response.data;
                const periods = Object.keys(apiData);
                const assistantNames = new Set<string>();
                periods.forEach((period: string) => {
                    const usages: AssistantUsage[] = apiData[period];
                    usages.forEach((usage) => {
                        assistantNames.add(usage.assistant_name);
                    });
                });
                const assistantNamesArray = Array.from(assistantNames);
                const chartData: (string)[][] = [[t("stat.year_month"), ...assistantNamesArray]];

                periods.forEach((period: string) => {
                    const usages: AssistantUsage[] = apiData[period];
                    const row: any = [period];
                    assistantNamesArray.forEach((assistantName) => {
                        const usage = usages.find((usage) => usage.assistant_name === assistantName);
                        row.push(usage ? usage.count : 0);
                    });
                    chartData.push(row);
                });
                setData(chartData);
                setOriginalData(chartData);
            }).catch((error) => {
                console.error(error)
            })
        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getStat();
    }, [selectPeriod]);

    useEffect(() => {
        setChartEvents([
            {
                eventName: "select",
                callback: ({chartWrapper}: any) => {
                    const chart = chartWrapper.getChart();
                    const selection = chart.getSelection();

                    //console.log('Selection', chartWrapper.getChart().getSelection());

                    if (selection.length === 0) {
                        return;
                    }

                    const selectedItem = selection[0];
                    const columnIndex = selectedItem.column;

                    if (selectedLine === columnIndex) {
                        //setData(originalData);
                        //setSelectedLine(null);
                    } else {
                        //const updatedData = originalData.map(row => [row[0], row[columnIndex]]);
                        //setData(updatedData);
                        //setSelectedLine(columnIndex);
                    }

                    //chartWrapper.setDataTable(data);
                    //chartWrapper.draw();
                },
            },
        ]);
    }, [data, originalData, selectedLine]);

    return (
        <div className="card shadow-sm">
            <div className="card-header">
                <h5 className="mb-0">{t("stat.assistant_stat_title")}</h5>
                <p className="mb-0">{t("stat.assistant_stat_subtitle1")}</p>
            </div>
            <div className="card-body">
                <div>
                    <ToggleButtonGroup type="radio" name="options" defaultValue={1}
                                       onChange={(value) => setSelectPeriod(value)}>
                        <ToggleButton id="tbg-radio-1" value={1}>
                            {t("stat.daily")}
                        </ToggleButton>
                        <ToggleButton id="tbg-radio-2" value={2}>
                            {t("stat.weekly")}
                        </ToggleButton>
                        <ToggleButton id="tbg-radio-3" value={3}>
                            {t("stat.monthly")}
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>
                <Chart
                    chartType="LineChart"
                    data={data}
                    options={options}
                    width={"100%"}
                    height={"400px"}
                    chartEvents={chartEvents}
                    ref={chartRef}
                />
            </div>
        </div>
    );
};

export default AssistantStat;
