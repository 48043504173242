import {useContext, useEffect} from "react";
import ChatContext from "../context/ChatContext";
import {Api} from "../../../services/api";
import {IAIResponse} from "../../../interfaces/IAIResponse";

export const useSendMessage = () => {
    const {
        message,
        setMessage,
        setMessages,
        threadId,
        setIsLoading,
        assistantId
    } = useContext(ChatContext);

    return async () => {
        setIsLoading(true);

        let data = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify({
                thread_id: threadId,
                assistant_id: assistantId,
                message: message,
                platform: 'react'
            })
        };

        // Elküldjük a felhasználó üzenetét és előkészítjük az asszisztens válaszát
        setMessages(prevState => ([
                ...prevState,
                {
                    "role": "user",
                    "content": message
                },
                {
                    "role": "assistant",
                    "content": ""
                }
            ])
        );

        const response = await Api.raw<any>(`chat/chat`, data);

        if (!response.body) {
            setIsLoading(false);
            return;
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        let done = false;
        // let buffer = '';

        /*while (!done) {
            const {value, done: doneReading} = await reader.read();
            done = doneReading;
            const chunk = decoder.decode(value, {stream: true});
            // buffer += chunk;
            // Feldolgozás darabonként
            setMessages(prevState => {
                const updatedState = [...prevState];
                if (updatedState.length > 0 && chunk.length > 0) {
                    updatedState[updatedState.length - 1].content = chunk;
                }
                return updatedState;
            });
        }*/

        let buffer = '';  // Buffer az adatok gyűjtésére

        while (!done) {
            const {value, done: doneReading} = await reader.read();
            done = doneReading;
            if (value) {
                const chunk = decoder.decode(value, {stream: true});
                buffer += chunk;

                // Keressük az új sor karaktert, amely elválasztja a JSON objektumokat
                let boundary = buffer.indexOf('\n');
                while (boundary !== -1) {
                    // Kivesszük a JSON objektumot a bufferből
                    const potentialJson = buffer.substring(0, boundary).trim();
                    buffer = buffer.substring(boundary + 1);

                    if (potentialJson) {
                        try {
                            // Parse-oljuk a JSON objektumot
                            const json = JSON.parse(potentialJson);

                            if (json.type === "chunk" || json.type === "completed") {
                                setMessages(prevState => {
                                    const updatedState = [...prevState];
                                    if (updatedState.length > 0 && json.content.length > 0) {
                                        updatedState[updatedState.length - 1].type = json.type;
                                        updatedState[updatedState.length - 1].content = json.content;

                                        if(json.type === "completed") {
                                            updatedState[updatedState.length - 1].sources = json.sources;
                                            updatedState[updatedState.length - 1].message_id = json.message_id;
                                            console.log('Sources: ', json.sources)
                                        }
                                    }
                                    return updatedState;
                                });
                            }
                            if (json.type === "completed") {
                                setIsLoading(false);
                            }
                        } catch (e) {
                            console.error("JSON.parse error: ", e);
                        }
                    }
                    boundary = buffer.indexOf('\n');
                }
            }
        }

        setMessage('');
        setIsLoading(false);
    }
}
