import {PiCheck, PiCopy} from "react-icons/pi";
import React from "react";
import toast from "react-hot-toast";
import TooltipLayer from "components/tooltip/tooltip";
import {t, use} from "i18next";

const MessageClipboardBtn = ({content}: { content: string }) => {

    const removeHtmlTags = (htmlContent: string) => {
        return htmlContent.replace(/<[^>]*>?/gm, '');
    }

    const copyToClipboard = (htmlContent: string) => {
        const plainText = removeHtmlTags(htmlContent);
        navigator.clipboard.writeText(plainText);
        toast.success(t("message_buttons.copy_success"));
    }

    return <TooltipLayer title={t("message_buttons.copy")} id="message-btn-copy">
        <button type="button" onClick={e => copyToClipboard(content)}
                className="btn btn-link btn-sm d-flex justify-content-center align-items-center">
            <PiCopy size={18}/>
        </button>
    </TooltipLayer>
}

export default MessageClipboardBtn;