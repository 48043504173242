import React, {FormEvent, useState} from 'react';
import {Button, Form, Modal, OverlayTrigger, Tooltip} from "react-bootstrap";
import {AiOutlineUsergroupAdd} from "react-icons/ai";
import {BsPencilFill} from "react-icons/bs";

import {IGroup} from "interfaces/IGroup";
import {t} from "i18next";
import PermissionCheck from "components/permissioncheck/permissioncheck";
import {Api} from "services/api";

interface GroupFormProps {
    group?: IGroup,
    getGroups: () => Promise<void>
}

const Group = ({group, getGroups}: GroupFormProps) => {
    const [showModal, setShowModal] = useState(false);
    const [name, setName] = useState<string>(group?.name || '');

    const processForm = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const url = `/groups/${group !== undefined ? group!.id : 'add'}`;

        await Api.post<IGroup[]>(url, {
            'name': name
        });
        setShowModal(false);
        void getGroups();
    }

    return (
        <PermissionCheck permission={['group.' + (group === undefined ? 'add_group' : 'change_group')]}>
            {group === undefined &&
                <Button
                    size="sm"
                    onClick={e => {
                        setShowModal(true)
                    }}
                >
                    <AiOutlineUsergroupAdd/> <span className="ms-2">{t('group.addGroup')}</span>
                </Button>
            }
            {group !== undefined &&
                <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="button-tooltip-2">
                        <div>{t('group.group.iconTooltip')}</div>
                    </Tooltip>}
                >
                    <Button
                        variant="outline-secondary"
                        size="sm"
                        onClick={e => {
                            setShowModal(true)
                        }}
                    >
                        <BsPencilFill/>
                    </Button>
                </OverlayTrigger>
            }


            <Modal show={showModal} onHide={() => setShowModal(false)} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{t("group.group." + (group !== undefined ? 'edit' : 'add') + "ModalTitle")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={processForm}>
                        <Form.Group
                            className="my-3"
                            controlId="name"
                        >
                            <Form.Label>{t('group.group.name')}</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={t('group.group.namePlaceholder')}
                                required={true}
                                value={name}
                                onChange={e => setName(e.target.value)}
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            {t('group.group.process')}
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </PermissionCheck>
    );
};

export default Group;