import {Link, useParams} from 'react-router-dom';
import React, {useContext, useEffect, useState} from "react";
import DataContext from "context/DataContext";
import {Api} from "services/api";
import {t} from "i18next";
import {
    Button,
    Spinner,
    Stack,
    Table,
    Container,
    Card,
    Badge,
    Form,
    InputGroup,
    Tooltip,
    useAccordionButton,
    Accordion,
    AccordionContext,
} from "react-bootstrap";
import {IoSearch} from "react-icons/io5";
import {IoMdAdd, IoMdArrowRoundBack} from "react-icons/io";
import toast, {Toaster} from "react-hot-toast";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {BsTrashFill} from "react-icons/bs";
import {FaFilter} from "react-icons/fa6";
import PermissionCheck from "../../../components/permissioncheck/permissioncheck";
import {usePermissions} from "../../../hooks/usePermissions";

interface AddOrDeleteDocumentBtnProps {
    id: number;
    assistant_id: number;
    from: string;
    isSet: boolean;
    fId?: string;
}

interface AssistantFiles {
    file_id: number;
}

interface Assistant {
    id: number;
    name: string;
    instructions?: string;
    short_description?: string;
    description?: string;
    model: string;
    assistant_id: number;
    files: AssistantFiles[];
}

interface CustomToggleProps {
    children: React.ReactNode;
    eventKey: string;
    documentFrom: string;
    callback?: (eventKey: string) => void;
}

interface FilterToggleProps {
    children: React.ReactNode;
    eventKey: string;
}

const AssistantDocuments = () => {
    const {id} = useParams();

    const {checkPermissions} = usePermissions();

    const {profile} = useContext(DataContext);
    const [documents, setDocuments] = useState<any[]>([]);
    const [assistant, setAssistant] = useState<Assistant>();
    const [loadingDocumentsList, setLoadingDocumentsList] = useState(false);
    const [loadingAssistant, setLoadingAssistant] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [accordionOpen, setAccordionOpen] = useState<boolean>(false);
    const [filterOpen, setFilterOpen] = useState<boolean>(false);
    const [aidocChecked, setAidocChecked] = useState(true);
    const [docType, setDocType] = useState('0');
    const [filterDate, setFilterDate] = useState('');

    const getAssistantDocuments = () => {
        if (profile.id && assistant?.id) {
            try {
                setLoadingDocumentsList(true);
                let queryParams = aidocChecked ? `?assistant_id=${assistant.id}` : '';
                Api.get<Assistant[]>(`assistant/documents-list${queryParams}`).then((response) => {
                    setDocuments(response);
                    setLoadingDocumentsList(false);
                }).catch((error) => {
                    console.error(error)
                })
            } catch (e) {
                console.error(e)
            }
        }
    };

    const handleAidocCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAidocChecked(e.target.checked);
        filterAssistantDocuments(e.target.checked, docType, filterDate);
    };

    const handleDocTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setDocType(e.target.value);
        filterAssistantDocuments(aidocChecked, e.target.value, filterDate);
    };

    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setFilterDate(e.target.value);
        filterAssistantDocuments(aidocChecked, docType, e.target.value);
    };

    const filterAssistantDocuments = (isChecked: boolean, docType: string, filterDate: string) => {
        if (profile.id && assistant?.id) {
            try {
                let queryParams = isChecked ? `?assistant_id=${assistant.id}` : '';
                queryParams += docType !== '0' ? `${queryParams ? '&' : '?'}from=${docType}` : '';
                queryParams += filterDate ? `${queryParams ? '&' : '?'}created_at=${filterDate}` : '';
                setLoadingDocumentsList(true);
                Api.get<Assistant[]>(`assistant/documents-list${queryParams}`).then((response) => {
                    setDocuments(response);
                    setLoadingDocumentsList(false);
                }).catch((error) => {
                    console.error(error);
                    setLoadingDocumentsList(false);
                });
            } catch (e) {
                console.error(e);
                setLoadingDocumentsList(false);
            }
        }
    };

    const getAssistant = () => {
        if (profile.id && id) {
            try {
                setLoadingAssistant(true);
                Api.get<Assistant>('assistant/assistant/' + id).then((response) => {
                    setAssistant(response);
                    setLoadingAssistant(false);
                }).catch((error) => {
                    setLoadingAssistant(false);
                    toast.error(t("assistants.assistent_loading_error"));
                    console.error(error)
                })
            } catch (e) {
                setLoadingAssistant(false);
                console.error(e)
            }
        }
    };

    useEffect(() => {
        checkPermissions(['assistant.view_assistant_documents']);

        const apiUrl = localStorage.getItem('apiUrl');
        if (!apiUrl) {
            localStorage.removeItem('token');
            localStorage.removeItem('user_id');
            window.location.href = '/edit-url';
        }
    }, []);

    useEffect(getAssistant, [id, profile.id]);
    useEffect(getAssistantDocuments, [assistant?.id, profile.id]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    const filteredDocuments = documents.filter(document =>
        document.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const AccordionCustomToggle = ({children, eventKey, documentFrom, callback}: CustomToggleProps) => {
        const {activeEventKey} = useContext(AccordionContext);

        const decoratedOnClick = useAccordionButton(
            eventKey,
            () => callback && callback(eventKey),
        );

        const isCurrentEventKey = activeEventKey === eventKey;

        return (
            <>
                {documentFrom === 'audio' ? (
                    <div onClick={decoratedOnClick} className={`accordion-title ${isCurrentEventKey ? 'active' : ''}`}>
                        {children}
                    </div>) : (
                    <div>
                        {children}
                    </div>
                )}
            </>
        );
    }

    const AccordionCustomBodyToggle = ({children, eventKey, documentFrom}: CustomToggleProps) => {
        const decoratedOnClick = useAccordionButton(eventKey);
        return (
            <>
                {documentFrom === 'audio' ? (
                    <div onClick={decoratedOnClick} style={{'cursor': 'pointer'}}>
                        {children}
                    </div>) : (
                    <div>
                        {children}
                    </div>
                )}
            </>
        );
    }

    const FilterToggle = ({children, eventKey}: FilterToggleProps) => {
        return (
            <button
                type="button"
                className="btn btn-link"
                onClick={() => setFilterOpen(!filterOpen)}
            >
                {children}
            </button>
        );
    }

    return (
        <PermissionCheck permission="assistant.view_assistant_documents" message={t('permission.permViewAlert')}>
            <Container className="position-relative pb-5 chat-container">
                <h4 className="mt-4">{t("assistants.document_assistant_title")}</h4>
                <div className="mt-4">
                    <Link to="/">
                        <Button variant="primary" size="sm">
                            <div className="d-flex justify-content-start align-items-center">
                                <IoMdArrowRoundBack size={18}/>
                                <span className="ms-2">{t('assistants.back')}</span>
                            </div>
                        </Button>
                    </Link>
                </div>
                <Card className="mt-4">
                    <Card.Body>
                        <Card.Title>{t('assistants.assistant')}</Card.Title>
                        <Card.Text as="div">
                            {loadingAssistant &&
                                <div className="text-center py-4"><Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner></div>}

                            {!loadingAssistant && assistant?.id && <Table bordered hover size="sm" className="mb-0">
                                <tbody>
                                <tr>
                                    <th className="text-end px-2" style={{width: '120px'}}>{t("assistants.name")}:
                                    </th>
                                    <td className="px-2"><b>{assistant?.name}</b></td>
                                </tr>
                                <tr>
                                    <th className="text-end px-2"
                                        style={{width: '120px'}}>{t("assistants.description")}:
                                    </th>
                                    <td className="px-2">
                                        <div dangerouslySetInnerHTML={{__html: assistant?.short_description ?? ''}}/>
                                        <div dangerouslySetInnerHTML={{__html: assistant?.description ?? '-'}}/>
                                    </td>
                                </tr>
                                </tbody>
                            </Table>}
                        </Card.Text>
                    </Card.Body>
                </Card>
                <div className="mt-4">
                    <h5 className="mb-3 mx-3">{t('assistants.documents')}</h5>
                    {documents.length === 0 && !loadingDocumentsList && !assistant?.id ?
                        <div className="mx-3 mt-3">{t('assistants.noAssistant')}</div> : ''}
                    {loadingDocumentsList &&
                        <div className="text-center py-4"><Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner></div>}

                    {!loadingDocumentsList && assistant?.id &&
                        <div className="card shadow-sm mb-3 px-3 py-2">
                            <div className="py-3">
                                <InputGroup>
                                    <InputGroup.Text id="search">
                                        <IoSearch/>
                                    </InputGroup.Text>
                                    <Form.Control
                                        type="search"
                                        id="inputSearch"
                                        aria-describedby="search"
                                        placeholder={t("assistants.search")}
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                    />
                                    <InputGroup.Text id="search-block">
                                        <FilterToggle eventKey="0">
                                            <div className="d-inline-flex justify-content-start align-items-center">
                                                <FaFilter/>
                                                <span className='ms-2'>{t("doc_filter.filter")}</span>
                                            </div>
                                        </FilterToggle>
                                    </InputGroup.Text>
                                </InputGroup>
                                <div className={`card mt-2 ${filterOpen ? '' : 'd-none'}`}>
                                    <div className="card-header">{t("doc_filter.search")}</div>
                                    <div className="card-body">
                                        <div className="d-flex flex-column">
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="checkbox"
                                                       id="aidoc" onChange={handleAidocCheckboxChange}
                                                       checked={aidocChecked}/>
                                                <label className="form-check-label" htmlFor="aidoc">
                                                    {t("doc_filter.assistant_docs")}
                                                </label>
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-check-label" htmlFor="aidoc">
                                                    {t("doc_filter.doc_type")}
                                                </label>
                                                <select className="form-select" id="doctype" value={docType}
                                                        onChange={handleDocTypeChange}>
                                                    <option value="0">{t("doc_filter.all")}</option>
                                                    <option value="audio">{t("doc_filter.audio")}</option>
                                                    <option value="document">{t("doc_filter.document")}</option>
                                                </select>
                                            </div>
                                            <div className="d-none">
                                                <label htmlFor="dateFilter" className="form-label">Dátum</label>
                                                <input type="date" className="form-control" id="dateFilter" onChange={handleDateChange} value={filterDate} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Accordion defaultActiveKey="-1">
                                <Table responsive="sm" hover className={accordionOpen ? 'align-top' : 'align-middle'}>
                                    <thead>
                                    <tr>
                                        <th>{t("documents.table.date")}</th>
                                        <th className="text-start">{t("assistants.table.name")}</th>
                                        <th className="text-center">{t("assistants.table.type")}</th>
                                        <th className="text-end">{t("assistants.table.operations")}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {filteredDocuments.map((document: any, index: number) => (
                                        <tr key={index}>
                                            <td>{document.created_at}</td>
                                            <td>
                                                <div
                                                    className="d-flex justify-content-start align-items-start flex-column">
                                                    <AccordionCustomToggle eventKey={index.toString()}
                                                                           documentFrom={document.from}>
                                                        {document.title}
                                                    </AccordionCustomToggle>
                                                    {document.from === 'audio' &&
                                                        <div className='w-100'>
                                                            <Accordion.Item eventKey={index.toString()}
                                                                            className='border-0 p-0'>
                                                                <Accordion.Body className='p-0'
                                                                                onEntering={() => setAccordionOpen(true)}
                                                                                onExit={() => setAccordionOpen(false)}>
                                                                    <AccordionCustomBodyToggle
                                                                        eventKey={index.toString()}
                                                                        documentFrom={document.from}>
                                                                        <Card
                                                                            className='shadow-sm mt-2 accordian-card'>
                                                                            <Card.Body className='py-2'>
                                                                        <pre
                                                                            style={{'whiteSpace': 'pre-wrap'}}>{document.description}</pre>
                                                                            </Card.Body>
                                                                        </Card>
                                                                    </AccordionCustomBodyToggle>
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                        </div>
                                                    }
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <Badge pill bg="secondary">
                                                    {t('assistants.table.from.' + document.from)}
                                                </Badge>
                                            </td>
                                            <td className="text-end">
                                                <Stack direction="horizontal" gap={2} className="justify-content-end">
                                                    <AddOrDeleteDocumentBtn id={document.id}
                                                                            assistant_id={assistant?.id ?? 0}
                                                                            from={document.from}
                                                                            isSet={assistant.files.some(file => file.file_id === document.file_id)}
                                                                            fId={document.file_id}
                                                    />
                                                </Stack>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </Accordion>
                        </div>
                    }
                </div>
                <Toaster/>
            </Container>
        </PermissionCheck>
    );
}

const AddOrDeleteDocumentBtn = ({id, assistant_id, from, isSet, fId}: AddOrDeleteDocumentBtnProps) => {
    const [loading, setLoading] = useState(false);
    const [uploaded, setUploaded] = useState(isSet);
    const [fileId, setFileId] = useState(fId);
    const handleAddClick = () => {
        const formdata = new FormData();
        formdata.append("id", id.toString());
        formdata.append("from", from);
        formdata.append("assistant_id", assistant_id.toString());

        try {
            setLoading(true)
            Api.raw('assistant/upload-document-to-openai', {
                method: 'POST',
                body: formdata,
                headers: {}
            }).then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(t("assistants.document_error"));
                }
            }).then((data: any) => {
                console.log(data)
                setFileId(data.file_id);
                setLoading(false);
                setUploaded(true);
                toast.success(t('assistants.document_added'));
            }).catch((e) => {
                setLoading(false)
                toast.error(t("assistants.document_error"));
            }).finally(() => {
                setLoading(false)
            });
        } catch (e) {
            console.error(e)
            setLoading(false)
        }
    }

    const handleDeleteClick = () => {
        const confirmed = window.confirm(t('assistants.document_remove_confirm'));
        if (!confirmed) return;

        try {
            setLoading(true)
            Api.post('assistant/delete-document-from-assistant', {
                assistant_id: assistant_id,
                file_id: fileId,
            }).then((response: any) => {
                if (response.ok || response.success) {
                    setUploaded(false);
                    toast.success(t('assistants.document_remove_success'));
                } else {
                    throw new Error(t("assistants.document_remove_error"));
                }
            }).catch((e) => {
                setLoading(false)
                toast.error(t("assistants.document_remove_error"));
            }).finally(() => {
                setLoading(false)
            });
        } catch (e) {
            console.error(e)
            setLoading(false)
        }
    }

    return (
        <div className="text-center">
            {loading ? <Spinner animation="border" size="sm" variant="primary" className="d-inline-block"/> :
                uploaded && fileId ? <Button variant="outline-danger" size="sm" onClick={handleDeleteClick}>
                    <BsTrashFill size={18}/>
                </Button> : <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="button-tooltip-2">
                        <div>{t('assistants.table.add')}</div>
                    </Tooltip>}
                >
                    <Button variant="outline-secondary" size="sm" onClick={handleAddClick}>
                        <div className="d-flex justify-content-start align-items-center">
                            <IoMdAdd size={18}/>
                        </div>
                    </Button>
                </OverlayTrigger>
            }
        </div>
    );
}

export default AssistantDocuments;