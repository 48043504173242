import {Tab, Tabs} from "react-bootstrap";
import {useState} from "react";
import {t} from "i18next";
import Threads from "./Threads";
import Files from "./Files";

const SidePanel = () => {
    const [key, setKey] = useState<string>('threads');

    return (
        <>
            <div id="cc-side-panel">
                <div id="cc-side-panel-content">
                    <Tabs
                        activeKey={key}
                        onSelect={(k) => setKey(k || '')}
                    >
                        <Tab eventKey="threads" title={t("chat.sidePanel.tab.threads")} className="threads">
                            <Threads />
                        </Tab>
                        <Tab eventKey="files" title={t("chat.sidePanel.tab.files")} className="files">
                            <Files />
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </>
    );
}
export default SidePanel;