import {BiDislike} from "react-icons/bi";
import React, {useContext, useEffect, useState} from "react";
import {Modal, Button} from "react-bootstrap";
import TooltipLayer from "../../../../components/tooltip/tooltip";
import {t, use} from "i18next";
import {Api} from "../../../../services/api";
import toast from "react-hot-toast";
import DataContext from "../../../../context/DataContext";
import {IMessage} from "../../../../interfaces/IMessage";

interface IDislike {
    title: string;
    created_at: string;
}

const MessageDislikeBtn = ({message}: { message: IMessage }) => {
    const {profile} = useContext(DataContext);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [dislikes, setDislikes] = useState<IDislike[]>([]);
    const [title, setTitle] = useState('');
    const [text, setText] = useState('');

    useEffect(() => {
        try {
            Api.get<IDislike[]>('dislike/list').then((response) => {
                setDislikes(response);
            }).catch((e) => {
                console.error(e);
            });
        } catch (e) {
            console.error(e);
        }
    }, []);

    const handleSubmitDislike = () => {

        let data = title + (text.length > 0 ? '\n\n' + text : '');

        const formData = new FormData();
        formData.append('platform', 'react');
        formData.append('event_name', 'dislike_message');
        formData.append('user_id', profile.id.toString());
        formData.append('model_name', 'Chat');
        if(message.message_id) {
            formData.append('model_id', message.message_id.toString());
        }
        formData.append('details', data);

        try {
            Api.raw('log/create', {
                method: 'POST',
                body: formData,
                headers: {}
            }).then((response: any) => {
                setTitle('');
                setText('');
                toast.success(t("message_buttons.dislike.success"));
            }).catch((e) => {
                toast.error(t("message_buttons.dislike.error"));
            });
        } catch (e) {
            console.error(e)
        } finally {
            setShowModal(false);
        }
    }

    return <>
        {message.message_id && <TooltipLayer title={t("message_buttons.dislike_modal_title")} id="tt-02">
            <button type="button"
                    className="btn btn-link btn-sm d-flex justify-content-center align-items-center"
                    onClick={e => setShowModal(true)}>
                <BiDislike size={18}/>
            </button>
        </TooltipLayer>}

        <Modal show={showModal} onHide={() => setShowModal(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title as='span'>{t("message_buttons.dislike_modal_title")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='mb-2 text-center'>
                    <strong>{t("message_buttons.dislike_modal_text")}:</strong>
                </div>
                <div className="d-inline-flex flex-wrap gap-2 justify-content-center">
                    {dislikes.map((dislike: any, index: number) => (
                        <button
                            key={index}
                            type="button"
                            className={`btn btn-outline-dark btn-sm ${title === dislike.title ? 'active' : ''}`}
                            onClick={(e) => setTitle(dislike.title)}>
                            <span>{dislike.title}</span>
                        </button>
                    ))}
                </div>
                <hr/>
                <div>
                    {title.length > 0 && <div className="mb-2"><strong>Válaszva: {title}</strong></div>}
                    <textarea className="form-control" rows={3}
                              placeholder={t("message_buttons.dislike_modal_text2")}
                              onChange={(e) => setText(e.target.value)}
                    />
                </div>
            </Modal.Body>
            {title.length > 0 && <Modal.Footer>
                <Button variant="dark" onClick={handleSubmitDislike}>Küldés</Button>
            </Modal.Footer>}
        </Modal>
    </>
}

export default MessageDislikeBtn;