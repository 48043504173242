import {useContext, useEffect, useState} from "react";
import {Api} from "../../../services/api";
import ChatContext from "../context/ChatContext";
import {Button, Spinner} from "react-bootstrap";
import {
    BsCameraVideoFill,
    BsFillFileEarmarkFill,
    BsFillVolumeUpFill,
    BsTrash,
    BsYoutube
} from "react-icons/bs";
import {t} from "i18next";
import toast from "react-hot-toast";
import FileType from "../../../components/FileType";
import {IAssistantFile} from "../../../interfaces/IAssistantFile";


const Files = () => {
    const {assistantId} = useContext(ChatContext);
    const [files, setFiles] = useState<IAssistantFile[]>([]);
    useEffect(() => {
        Api.get<IAssistantFile[]>(`/assistant/${assistantId}/files`).then(response => {
            setFiles(response);
        })
    }, []);

    const handleDeleteClick = (file: IAssistantFile) => {
        const confirmed = window.confirm(t('assistants.document_remove_confirm'));
        if (!confirmed) return;

        try {
            Api.post('assistant/delete-document-from-assistant', {
                assistant_id: assistantId,
                file_id: file.file_id,
            }).then((response: any) => {
                if (response.ok || response.success) {
                    setFiles(prevState => prevState.filter((f: IAssistantFile) => f.file_id !== file.file_id));
                    toast.success(t('assistants.document_remove_success'));
                } else {
                    throw new Error(t("assistants.document_remove_error"));
                }
            }).catch((e) => {
                toast.error(t("assistants.document_remove_error"));
            }).finally(() => {
            });
        } catch (e) {
            console.error(e)
        }
    }

    const showSummary = (file: IAssistantFile) => {
        setFiles(prevFiles =>
            prevFiles.map(f =>
                f.file_id === file.file_id
                    ? {...f, showSummary: !f.showSummary}
                    : f
            )
        )
    }

    return (
        <>
            {files.length > 0 && <ul>
                {files.map((file) => (
                    <li key={file.file_id}>
                        <div className="file-details">
                            <div className="file-title" onClick={e => showSummary(file)}>
                                <span className="me-2">
                                   <FileType file={file} />
                                </span>
                                {file.title}
                            </div>
                            <div><Button
                                variant="dark"
                                onClick={e => handleDeleteClick(file)}
                            >
                                <BsTrash/>
                            </Button>
                            </div>
                        </div>
                        <div className={"file-summary " + (file.showSummary === true ? "active" : "")}>
                            {file.description}
                        </div>
                    </li>
                ))}
            </ul>}
        </>
    );
}
export default Files;