import React, {useContext, useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import {Toaster} from "react-hot-toast";
import {t} from "i18next";
import {BsLayoutSidebar} from "react-icons/bs";

import DataContext from "context/DataContext";
import PermissionCheck from "components/permissioncheck/permissioncheck";

import {ChatDataProvider} from "./context/ChatContext";
import Form from "./components/Form";
import MessageList from "./components/MessageList";
import {useInitialData} from "./hooks/useInitialData";
import SidePanel from "./components/SidePanel";

import './ChatPage.css';
import {usePermissions} from "../../hooks/usePermissions";

const ChatPage = () => {
    const {checkPermissions} = usePermissions();

    const [show, setShow] = useState(false);

    const handleClick = () => setShow(prevState => !prevState);

    useEffect(() => {
        checkPermissions(['chat.view_chat']);
    }, []);

    return (
        <>
            <ChatDataProvider value={useInitialData()}>
                <PermissionCheck permission="chat.view_chat"
                                 message={t('permission.permViewAlert')}>
                    <div id="cc-side-panel-button-container">
                        <Button onClick={handleClick} className="position-absolute top-0 left-0 m-3 z-3">
                            <BsLayoutSidebar/>
                        </Button>
                    </div>

                    <div id="cc-chat-grid" className={(show ? ' opened' : '')}>
                        <SidePanel/>
                        <MessageList showAssistantDetails={true} showBackButton={true} showSourcesButton={true}/>
                        <Form showBotsBtn={true}/>
                    </div>
                    <Toaster/>

                </PermissionCheck>
            </ChatDataProvider>
        </>
    );
}

export default ChatPage;