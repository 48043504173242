import i18next from 'i18next';
import {initReactI18next} from 'react-i18next';
import * as hu from "./hu.json";
import * as en from "./en.json";
import {defaultLanguage} from "../../context/LanguageContext";

const resources = {
    en: {
        translation: en
    },

    hu: {
        translation: hu
    }
};

i18next.use(initReactI18next).init({
    //lng: 'hu', // if you're using a language detector, do not define the lng option
    lng: localStorage.getItem('lang') || defaultLanguage,
    debug: false,
    resources,
    // if you see an error like: "Argument of type 'DefaultTFuncReturn' is not assignable to parameter of type xyz"
    // set returnNull to false (and also in the i18next.d.ts options)
    // returnNull: false,
});