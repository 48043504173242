import React, {useEffect, useState} from "react";
import {Outlet} from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import PageNavbar from "components/navbar/navbar";
import {IProfile} from "interfaces/IProfile";
import {DataProvider } from "context/DataContext";
import {Api} from "services/api";
import i18next, {t} from "i18next";
import {defaultLanguage, LanguageContext, languages} from "context/LanguageContext";
import {useInitialData} from "./hooks/useInitialData";

const App = () => {

    const [languageState, setLanguageState] = useState<string>(localStorage.getItem('lang') || defaultLanguage);

    const initialData = useInitialData();

    useEffect(() => {
        document.title = "Cognitudo";
        if (localStorage.getItem('token')) {
            Api.get<IProfile>('account/details')
                .then(response => initialData.setProfile(response))
                .catch(error => {
                    localStorage.removeItem('token')
                    localStorage.removeItem('user_id')
                })
        }
    }, []);

    const changeLanguage = async (lang: string) => {
        return await i18next.changeLanguage(lang);
    }

    useEffect(() => {
        if (languageState) {
            changeLanguage(languageState)
                .then(() => {
                })
                .catch(error => {
                    console.error(error)
                });
        }
    }, [languageState]);

    return (
        <>
            <DataProvider value={initialData}>
                <LanguageContext.Provider value={{ language: languageState, setLanguage: setLanguageState, languages, defaultLanguage }}>
                    <PageNavbar/>
                    <div className="d-flex flex-grow-1 position-relative">
                        <Outlet/>
                    </div>
                </LanguageContext.Provider>
            </DataProvider>
        </>
    );
}

export default App;
