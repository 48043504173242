import {useEffect, useState} from "react";
import {Chart} from "react-google-charts";
import {Api} from "../../../services/api";
import {t} from "i18next";

export interface AssistantUsage {
    assistant_name: string;
    count: number;
}

export interface MonthlyData {
    [key: string]: AssistantUsage[];
}

const AssistantMonthlyStat = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<(string | number)[][]>([]);

    const options = {
        hAxis: {
            title: t('stat.interaction'),
            minValue: 0,
        },
        vAxis: {
            title: t('stat.year_month'),
        },
    };

    const getStat = async () => {
        try {
            setLoading(true);
            const response = await Api.get<any>('stats/monthly-assistant-usage');
            const apiData = response.data; // Ensure this is typed as MonthlyData
            const months = Object.keys(apiData);
            const assistantNames = new Set<string>();
            months.forEach((month: string) => {
                const usages: AssistantUsage[] = apiData[month];
                usages.forEach((usage) => {
                    assistantNames.add(usage.assistant_name);
                });
            });
            const assistantNamesArray = Array.from(assistantNames);
            const chartData: (string)[][] = [[t("stat.year_month"), ...assistantNamesArray]];

            months.forEach((month: string) => {
                const usages: AssistantUsage[] = apiData[month];
                const row: any = [month];
                assistantNamesArray.forEach((assistantName) => {
                    const usage = usages.find((usage) => usage.assistant_name === assistantName);
                    row.push(usage ? usage.count : 0);
                });
                chartData.push(row);
            });
            setData(chartData);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getStat();
    }, []);

    return (
        <div className="card shadow-sm">
            <div className="card-header">
                <h5 className="mb-0">{t("stat.assistant_stat_title2")}</h5>
            </div>
            <div className="card-body">
                <Chart
                    chartType="BarChart"
                    width="100%"
                    height="400px"
                    data={data}
                    options={options}
                />
            </div>
        </div>
    );
}

export default AssistantMonthlyStat;