import React, {useContext, useEffect, useRef, useState} from "react";
import {Stack, Card, Row, Col, OverlayTrigger, Popover, Tooltip, Button} from "react-bootstrap";
import Message from "./Message";
import MessageButtons from "./MessageButtons";
import ChatContext from "../context/ChatContext";
import {useSendMessage} from "../hooks/useSendMessage";
import {Api} from "../../../services/api";
import {IAIResponse} from "../../../interfaces/IAIResponse";
import {IMessageHistory} from "../../../interfaces/IMessageHistory";
import ISubtitle from "../../../interfaces/ISubtitle";
import Assistants from "../../assistant/Assistants";
import AssistantDetails from "./AssistantDetails";
import {IoMdArrowRoundBack} from "react-icons/io";
import {t} from "i18next";
import {Link, useNavigate} from 'react-router-dom';

// const handleScroll = () => {
//     const pageHeight = Math.max(
//         document.body.scrollHeight,
//         document.body.offsetHeight,
//         document.documentElement.clientHeight,
//         document.documentElement.scrollHeight,
//         document.documentElement.offsetHeight
//     );
//
//     window.scrollTo({
//         top: pageHeight,
//         behavior: 'smooth'
//     });
// };

interface IInstructions {
    id: number;
    instruction: string;
}

const MessageList = ({showAssistantDetails, showBackButton, showSourcesButton}: { showAssistantDetails: boolean, showBackButton: boolean, showSourcesButton: boolean}) => {
    const {
        messages,
        threadId,
        setThreadId,
        isLoading,
        setMessages,
        assistantId,
        setSelectedMessage
    } = useContext(ChatContext);

    const [instructions, setInstructions] = useState<IInstructions[]>([]);

    const messagesEndRef = useRef<HTMLDivElement>(null);

    const navigate = useNavigate();

    const handleScroll = () => {
        messagesEndRef.current?.scrollIntoView({behavior: 'smooth'});
    };
    useEffect(() => {
        if (messages.length > 0) {
            handleScroll();
            console.log(messages)
        }
    }, [messages]);

    useEffect(() => {
        Api.post<IMessageHistory>(`chat/get_thread_history/`, {
            thread_id: threadId,
            assistant_id: assistantId
        })
            .then((response) => {
                if (threadId !== response.thread_id) {
                    setThreadId(response.thread_id)
                    localStorage.setItem('thread_id', response.thread_id)
                }
                setMessages(response.messages);
            });
    }, [assistantId, threadId, setThreadId]);

    useEffect(() => {
        try {
            Api.post<IInstructions[]>('assistant/get_instructions', {
                assistant_id: assistantId,
            })
                .then((data) => {
                    setInstructions(data);
                });
        } catch (e) {
            console.log(e);
        }
    }, [assistantId]);

    const handleNavHome = () => {
        sessionStorage.setItem("tabKey", "assistants");
        navigate('/');
    }


    return (
        <>
            <div id="cc-message-list-container">
                {showBackButton &&
                    <div className="text-center mt-3 mb-4">
                        <Button variant="primary" size="sm" onClick={() => handleNavHome()}>
                            <div className="d-flex justify-content-start align-items-center">
                                <IoMdArrowRoundBack size={18}/>
                                <span className="ms-2">{t('assistants.back')}</span>
                            </div>
                        </Button>
                    </div>}
                {showAssistantDetails &&
                    <AssistantDetails/>
                }
                <div id="cc-message-list-content" className="px-3">
                    <div id="cc-message-list">
                        {messages.length > 0 &&

                            messages.map((message, index) => (
                                <Message
                                    key={index}
                                    role={message.role}
                                >
                                    <div dangerouslySetInnerHTML={{__html: message.content}}/>
                                    {message.role === 'assistant' && message.type === 'completed' &&
                                        <MessageButtons message={message} assistant_id={assistantId} sources_show={showSourcesButton}></MessageButtons>
                                    }
                                </Message>
                            ))
                        }
                        <div ref={messagesEndRef}/>
                    </div>
                    <div id="cc-instructions">
                        {messages.length === 0 &&
                            <>
                                {instructions.map((instruction, index) => (
                                    <div className="instruction" key={index}
                                         onClick={() => setSelectedMessage(instruction.instruction)}>
                                        {instruction.instruction}
                                    </div>
                                ))
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default MessageList;
