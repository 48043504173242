import {useContext, useEffect, useState} from "react";
import DataContext from "../../../context/DataContext";
import Offcanvas from "react-bootstrap/Offcanvas";
import {t} from "i18next";
import {ChatDataProvider} from "../../chat/context/ChatContext";
import {useInitialData} from "../../chat/hooks/useInitialData";
import PermissionCheck from "../../../components/permissioncheck/permissioncheck";
import MessageList from "../../chat/components/MessageList";
import Form from "../../chat/components/Form";
import toast, {Toaster} from "react-hot-toast";
import {Api} from "../../../services/api";

const OffcanvasChat = ({show, handleClose, chatbotID}: {
    show: boolean,
    handleClose: () => void,
    chatbotID: number
}) => {
    const {userPermissions} = useContext(DataContext);
    const [open, setOpen] = useState<boolean>(show);

    useEffect(() => {
        setOpen(show);
        if (show) {
           const toastId = toast.loading(t('Adatok frissítése folyamatban...'), {position: "top-center"})
            Api.get<any>(`chatbot/get-chat-history-export/${chatbotID}`).then((response) => {
                console.log(response);
                toast.success('Adatok frissítve.', {
                    id: toastId,
                    position: "top-center",
                });
            }).catch((error) => {
                console.error(error)
                toast.error('Hiba az adatok frissítése közben.', {
                    id: toastId,
                    position: "top-center"
                });
            })
        }
    }, [chatbotID, show]);

    return (
        <div className="stat-chat-offcanvas">
            <Offcanvas show={open} onHide={handleClose} className="stat-chat-offcanvas">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{t('stat.botName')}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <ChatDataProvider value={useInitialData()}>
                        <PermissionCheck permission="chat.view_chat"
                                         message={t('permission.permViewAlert')}>
                            <div className="d-flex flex-column h-100">
                                <div className="flex-grow-1 stat-chat-message-list-container">
                                    <MessageList showAssistantDetails={false} showBackButton={false} showSourcesButton={false}/>
                                </div>
                                <div><Form showBotsBtn={false}/></div>
                            </div>
                            <Toaster/>
                        </PermissionCheck>
                    </ChatDataProvider>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
}

export default OffcanvasChat;