import React from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

interface TooltipLayerProps {
    id: string;
    title: string;
    children: React.ReactNode;
}
const TooltipLayer: React.FC<TooltipLayerProps> = ({id, children, title}) => (
        <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
            <div style={{'cursor': 'pointer'}}>{children}</div>
        </OverlayTrigger>
    );

export default TooltipLayer;