import {useContext, useState} from "react";
import {t} from "i18next";
import DataContext from "../../../context/DataContext";


const Message = ({role, children}: { role: string, children: any })  => {
    const {profile} = useContext(DataContext);
    const [imageSrc, setImageSrc] = useState<string>(() => {
        if (role === 'assistant') {
            const assistantProfileImage = localStorage.getItem('assistant_profile_image');
            if (assistantProfileImage) {
                return assistantProfileImage;
            }
        }

        if (role === 'user') {
            if (profile.profile_image) {
                return profile.profile_image;
            }
        }

        return '/images/default-profile.png';
    });

    return (
        <div className={"cc-message-container " + role}>
            <div className="cc-image-container">
                <img src={imageSrc} alt={t('chat.' + role + 'ProfileImage')}/>
            </div>
            <div className="cc-message">{children}</div>
        </div>
    );
}

export default Message;